import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Select,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack,
  InputRightElement,
  InputGroup,
  Switch,
  RadioGroup,
  useColorMode
} from '@chakra-ui/react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import Location from 'aws-sdk/clients/location';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import awsExports from '../../aws-exports';
import { getPromoCode, searchAddress } from '../../graphql/queries';
import { signUpPage } from '../../utils/resources-en';
import { inputTextStyleProps } from '../../utils/stylesProps';
import VerifyForm from './verify-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { graphql } from '../../utils/api';
const {
  userId,
  firstName,
  middleName,
  lastName,
  passwordDesc,
  confirmPassword,
  phoneNumber,
  emailId,
  next,
} = signUpPage;

const SignUpForm = (props) => {
  const toast = useToast();
  const colorMode = useColorMode();
  const { switchToSignIn, signup, initialStep, deliveryAddress } = props;
  const [showFinish, setShowFinish] = useState(false);
  const [searchAddrInput, setSearchAddrInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [client, setClient] = useState(null);
  // const passwordSecure = false;
  const [ passwordSecure, setPasswordSecure] = useState(false);
  const [confirmPasswordSecure, setConfirmPasswordSecure] = useState(false);
  // const confirmPasswordSecure = false;
  const [signupState, setSignupState] = useState('signup');
  const [signupLoading, setSignupLoading] = useState(false);
  const [promoCodeList, setPromoCodeList] = useState();
  const [phoneNum, setPhoneNum] = useState('');
  const [addressCustomTypeDisable, setAddressCustomTypeDisable] =
    useState(true);
  const [longLat, setLongLat] = useState([]);
  const [value, setValue] = useState('1');
  const phoneNumberRegex = new RegExp(
    '^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$',
  );

 
 
  const contactValidationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email')
      .required('Email cannot be empty'),
    password: Yup.string().required('Password cannot be empty').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      "min length 6, One Uppercase, One Lowercase, One Number and One Special Case Character"),
    confirmPassword: Yup.string().required('confirm Password cannot be empty')
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          " Password and Confirm password do not match"
        )
      }),
      username: Yup.string()
      .required('User id cannot be empty')
      .matches(/^[a-zA-Z0-9]*$/, 'Username can only contain alphanumeric characters')
      .test(
        'len',
        'Must be less than 128 characters',
        (val) => val?.length < 128,
      ),
    given_name: Yup.string().required('Please enter First Name'),
    family_name: Yup.string().required('Please enter last Name'),
    phone_number: Yup.string()
    .required('Please enter Mobile Number')
    .test('editPhoneValidation', 'Invalid Mobile Number Format', (val) => {
      if (val) {
        return !(val.replace(/\ /g, '').length < 12);
      }
    }),
  });

  const [validationSchema, setValidationSchema] = useState(
    contactValidationSchema,
  );

  const formik = useFormik({
    initialValues: {
      username: '',
      given_name: '',
      middle_name: '',
      family_name: '',
      password: '',
      confirmPassword: '',
      country_code: '',
      phone_number: '',
      email: '',
      referredBy: '',
      referredCode: '',
      addressType: '',
      ContactCategory: '',
      firstName: '',
      middleName: '',
      lastName: '',
      addrLine1: '',
      addrLine2: '',
      city: '',
      addrState: '',
      postCode: '',
      country: 'USA',
      cardHolderName: '',
      cardNumber: '',
      ExpDate: '',
      paymentPostCode: '',
      // orderLineitemReplacement: false,
      // subscribeToNotification: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("referredbyy", values);
      try {
        if (showFinish) {
          setSignupLoading(true);
          const res = await signup({
            username: values.username,
            given_name: values.given_name,
            middle_name: values.middle_name,
            family_name: values.family_name,
            password: values.password,
            phone_number: values.phone_number,
            email: values.email,
            firstName: values.given_name,
            middleName: values.middle_name,
            lastName: values.family_name,
            addlSignupData: JSON.stringify({
              deliveryContact: {
                addressType: values.addressType,
                ContactCategory: values.ContactCategory,
                address: {
                  addrLine1: values.addrLine1,
                  addrLine2: values.addrLine2,
                  city: values.city,
                  addrState: values.addrState,
                  postCode: values.postCode,
                  country: 'USA',
                  longitude: longLat[0],
                  latitude: longLat[1],
                  markDefault: true,
                },
              },
              referredBy: promoCodeList?.data?.getPromoCode?.items[0]?.issuedByCustomerId || '',
              referredCode: values?.referredCode || '',
            }),
            addlPaymentDetails: JSON.stringify({
              paymentSetup: {
                cardHolderName: values.cardHolderName,
                cardNumber: values.cardNumber,
                ExpDate: values.ExpDate,
                postCode: values.paymentPostCode,
              },
            }),
          });

          setPhoneNum(res?.codeDeliveryDetails?.Destination);
          setSignupState('information');

          toast({
            title: 'Successfully created account',
            description: 'Verify with code from your Mobile to move forward',
          });
          setSignupLoading(false);
        }
      } catch (error) {
        console.log("error22:",error)
        setSignupLoading(false);
        toast({
          title: 'Error',
          description:
            error?.message || 'Something went wrong. Please try again',
        });
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
    validateForm,
    setFieldTouched,
  } = formik;

  const handleOnListItemClick = async (e) => {
    setSearchResults([]);
    const itemClickedStr = e.target.innerText.split(', ');
    setValues({
      ...values,
      addrLine1: itemClickedStr[0],
      city: itemClickedStr[1],
      addrState: itemClickedStr[2].trim(),
      postCode: itemClickedStr[3].trim(),
    });
    setSearchAddrInput(e.target.innerText);
    const data = await searchPlace(e.target.innerText);
    setLongLat(data.Results[0].Place.Geometry.Point);
  };

  const customerPromoCodes = async () => {
    try {
      const promoCodeLists = await graphql(
        graphqlOperation(getPromoCode, {
          promoCode: values.referredCode,
        })
      );
      console.log("promoCodeList", promoCodeLists);
      setPromoCodeList(promoCodeLists)
      if((promoCodeLists.data.getPromoCode.items[0] == null) || !(promoCodeLists.data.getPromoCode.items[0]?.issuedByCustomerId)) {
        toast({
          title: 'Warning',
          description: 'Please enter the valid referral Code',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
      return promoCodeLists?.data?.getPromoCode.items[0]!==null ? true : false;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const validateAddressDetails = () => {
    const valid =
      (values.addressType &&
        // values.firstName &&
        // values.lastName &&
        values.addrLine1 &&
        values.city &&
        values.postCode) !== '';

    if (!valid) {
      toast({
        title: 'Warning',
        description: 'Please enter the required address values',
        duration: 3000,
        isClosable: true,
      });
    }
    return valid;
  };
  const handleDetails = () => {
    const valid1 =
      (values.username &&
        // values.firstName &&
        // values.lastName &&
        values.given_name &&
        values.family_name &&
        values.phone_number &&
        values.password &&
        values.confirmPassword) !== '';

    if (!valid1) {
      toast({
        title: 'Warning',
        description: 'Please enter the required details',
        duration: 2000,
        isClosable: true,
      });
    }
    return;
  };

  const searchAddressFunc = async (str) => {
    try {
      const data = await API.graphql({
        query: searchAddress,
        variables: {
          input: {
            searchStr: str,
          },
        },
        authMode: 'API_KEY',
        authToken: awsExports.aws_appsync_apiKey,
      });

      setSearchResults(data?.data?.searchAddress?.items);
    } catch (err) {
      console.log(err);
    }
  };

  const searchPlace = async (place) => {
    const params = {
      IndexName: awsExports.aws_geo_mapIndexName,
      MaxResults: 10,
      Text: place,
    };

    try {
      const addrData = await client.searchPlaceIndexForText(params).promise();
      return addrData;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const debounceSearch = debounce(
    (searchText) => searchAddressFunc(searchText),
    300,
  );

  const handleChangeSearchInput = (e) => {
    setSearchAddrInput(e.target.value);
    debounceSearch(e.target.value);
  };

  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  useEffect(() => {
    if (initialStep === 1) {
      nextStep();
    } else {
      reset();
    }

    const createClient = async () => {
      const credentials = await Auth.currentCredentials();
      const clientData = new Location({
        credentials,
        region: awsExports.aws_project_region,
      });

      setClient(clientData);
    };

    createClient();

    if (deliveryAddress?.address?.addrLine1) {
      const address = deliveryAddress?.address;
      setValues({
        ...values,
        addrLine1: address?.addrLine1,
        city: address?.city,
        addrState: address?.addrState,
        postCode: address?.postCode,
      });
    }
  }, [initialStep]);

  useEffect(() => {
    validateForm();
  }, [validationSchema]);

  return (
    <Stack p="calc(2.2vw)" width={{base:"calc(65vw)", md:"calc(40vw)"}} justifyContent="center" className="blockBg" borderRadius="20px">
      {signupState === 'signup' ? (
        <form onSubmit={handleSubmit}>
          <Stack>
            <Text fontFamily="Lexend" fontSize="21.41px">Account Setup</Text>
          </Stack>
          

           
            {activeStep === 0 ? (
              <Stack spacing={5} mt="5">
                <FormControl
                  id="username"
                  isInvalid={!!errors.username && !!touched.username}
                  isRequired
                >
                  <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">
                    Username
                  </FormLabel>
                  {/* <Tooltip
                    hasArrow
                    label="(Ex:-sakula,sridhar420) -  Should be unique &amp; no special charecter allowed."
                    bg="brand.red"
                  >
                    <InfoIcon fontSize="s" color="brand.red" />
                  </Tooltip> */}
                  <Input
                     style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                    fontFamily="Lexend"
                    fontWeight="400"
                    variant="filled"
                    autoFocus="true"
                    type="text"
                    color={colorMode == "dark" ? "black": "black"}
                    name="username"
                    onBlur={handleBlur}
                    value={values.username}
                    onChange={(e) => {
                      setFieldValue('username', e.target.value);
                    }}
                    placeholder="e.g. Michel88"
                    _placeholder={{color:"grey"}}
                  />
                  <FormErrorMessage>{errors.username}</FormErrorMessage>
                </FormControl>
                <HStack spacing={7}>
                  <FormControl
                    id="given_name"
                    isInvalid={!!errors.given_name && !!touched.given_name}
                    isRequired
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">{firstName}</FormLabel>
                    <Input
                     style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      type="text"
                      name="given_name"
                      onBlur={handleBlur}
                      value={values.given_name}
                      onChange={(e) => {
                        setFieldValue('given_name', e.target.value);
                      }}
                      placeholder="e.g. Michel"
                      _placeholder={{color:"grey"}}
                    />
                    <FormErrorMessage>{errors.given_name}</FormErrorMessage>
                  </FormControl>
                  {/* <FormControl id="middle_name">
                    <FormLabel fontFamily="Inter" fontWeight="600">{middleName}</FormLabel>
                    <Input
                      variant="filled"
                      type="text"
                      onBlur={handleBlur}
                      value={values.middle_name}
                      onChange={(e) => {
                        setFieldValue('middle_name', e.target.value);
                      }}
                    />
                    <Box h="15px"> </Box>
                  </FormControl> */}
                  <FormControl
                    id="family_name"
                    isInvalid={!!errors.family_name && !!touched.family_name}
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">
                      {lastName}{' '}
                      <Text as="span" color="brand.red">
                        *
                      </Text>
                    </FormLabel>
                    <Input
                        style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      type="text"
                      color={colorMode == "dark" ? "black": "black"}
                      name="family_name"
                      onBlur={handleBlur}
                      value={values.family_name}
                      onChange={(e) => {
                        setFieldValue('family_name', e.target.value);
                      }}
                      placeholder="e.g. Bryn"
                      _placeholder={{color:"grey"}}
                    />
                    <FormErrorMessage> {errors.family_name}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <FormControl
                  id="password"
                  isInvalid={!!errors.password && !!touched.password}
                  isRequired
                >
                  <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">
                    {passwordDesc}
                  </FormLabel>
                  {/* <Tooltip
                    hasArrow
                    label="Min Length 6, One Numerical, One Caps, One Special Chatacter."
                    bg="brand.red"
                  >
                    <InfoIcon fontSize="s" color="brand.red" />
                  </Tooltip> */}
                  <InputGroup>
                  <Input
                      style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                     fontFamily="Lexend"
                     fontWeight="400"
                     color={colorMode == "dark" ? "black": "black"}
                    variant="filled"
                    type={!passwordSecure ? 'password' : 'text'}
                    onBlur={handleBlur}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Please enter password"
                    _placeholder={{color:"grey"}}
                  />
                  <InputRightElement>
            <Button
              tabIndex="-1"
              color="brand.black"
              variant="link"
              onClick={() => {
                setPasswordSecure(!passwordSecure);
              }}
            >
              {passwordSecure ? <FaEye  style={{color:"black" , backgroundColor:"white" }}/> : <FaEyeSlash  style={{color:"black" , backgroundColor:"white" }}/>}
            </Button>
          </InputRightElement>
          </InputGroup>
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="confirmPassword"
                  isInvalid={
                    !!errors.confirmPassword && touched.confirmPassword
                  }
                  isRequired
                >
                  <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">{confirmPassword}</FormLabel>
                   <InputGroup>
                  <Input
                      style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                     fontFamily="Lexend"
                     fontWeight="400"
                     color={colorMode == "dark" ? "black": "black"}
                    variant="filled"
                    type={!confirmPasswordSecure ? 'password' : 'text'}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    placeholder="Please Re-enter password"
                    _placeholder={{color:"grey"}}
                  />
                   <InputRightElement>
            <Button
              tabIndex="-1"
              color="brand.black"
              variant="link"
              onClick={() => {
                setConfirmPasswordSecure(!confirmPasswordSecure);
              }}
            >
              {confirmPasswordSecure ? <FaEye  style={{color:"black" , backgroundColor:"white" }}/> : <FaEyeSlash style={{color:"black" , backgroundColor:"white" }}/>}
            </Button>
          </InputRightElement>
          </InputGroup>
                  <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="email"
                  isInvalid={!!errors.email && !!touched.email}
                  isRequired
                >
                  <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">{emailId}</FormLabel>
                  <Input
                      style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                     fontFamily="Lexend"
                     fontWeight="400"
                    variant="filled"
                    type="email"
                    name="email"
                    color={colorMode == "dark" ? "black": "black"}
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value);
                    }}
                    placeholder="e.g. example@1800spirits.com"
                    _placeholder={{color:"grey"}}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <HStack >
                  <FormControl
                    id="phone_number"
                    isInvalid={!!errors.phone_number && !!touched.phone_number}
                    isRequired
                  >
                    <FormLabel  fontSize="15.29px" fontFamily="Inter" fontWeight="600">
                      Phone Number
                    </FormLabel>
                    {/* <HStack>
                      <Input variant='filled'
                        type="text"
                        width="70px"
                        onBlur={handleBlur}
                        id="country_code"
                        name="country_code"
                        value={values.country_code}
                        onChange={(e) => {
                          setFieldValue('country_code', e.target.value);
                        }}
                        placeholder="+1"
                      />
                      <Input
                        variant="filled"
                        type="text"
                        onBlur={handleBlur}
                        value={values.phone_number}
                        onChange={(e) => {
                          setFieldValue('phone_number', e.target.value);
                        }}
                        placeholder="e.g. 1234567891"
                      />
                    </HStack> */}
                    <PhoneInput
                    className="roundPhoneInput"
                    // buttonStyle={{borderRadius: "60px", backgroundColor: "white", width:'40px' }}
                   fontFamily="Lexend"
                   fontWeight="400"
                   style={{
                    color: colorMode === 'dark' ? 'black' : 'black', // Adjust the color based on color mode
                    // Adjust the border color based on color mode
                  }}
                  // color="#363636"
                  specialLabel={''}
                  countryCodeEditable={false}
                  country="us"
                  value={values.phone_number}
                  name="phone_number"
                  
                  // containerStyle={{ width: '100%', borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                  inputStyle={{ width: '100%', borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                  onBlur={() => {
                    setFieldTouched('phone_number', true);
                  }}
                  onChange={(value, country, e, formattedValue) => {
                    const tempValue = `+${value}`;
                    setFieldValue('phone_number', tempValue);
                  }}
                />
                    <FormErrorMessage>{errors.phone_number}</FormErrorMessage>
                  </FormControl>
                </HStack>
              </Stack>
            ): activeStep === 1 ? (
          
              <Stack spacing={4} mt="3">
                <Box position="relative">
                  <FormControl id="searchAddressInput">
                    <Input
                        style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      type="text"
                      color={colorMode == "dark" ? "black": "black"}
                      onChange={handleChangeSearchInput}
                      placeholder="Type your address here"
                      _placeholder={{color:"grey"}}
                      value={searchAddrInput}
                    />
                    <Text as="span" fontSize="sm" color="brand.red">
                      Addresses on routes - type US Highway / route / state route - for accurate search
                    </Text>
                  </FormControl>
                  {searchResults && searchResults.length > 0 ? (
                    <Box
                      alignItems="right"
                      position="absolute"
                      top="36px"
                      left="0px"
                      bg="White"
                      style={{
                        border: '1px solid #ACABAB',
                        color: colorMode ? 'black' : 'initial', // Set text color to black in dark mode
                      }}
                      p="5"
                      w="100%"
                      zIndex="99999"
                    >
                      <List spacing={3}>
                        {searchResults.map((eachResult, idx) => (
                          <ListItem
                            key={idx}
                            cursor="pointer"
                            onClick={handleOnListItemClick}
                          >
                            {`${eachResult.street_line}, ${eachResult.city}, ${eachResult.state}, ${eachResult.zipcode}`}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <HStack>
                  <FormControl
                    id="addressType"
                    isInvalid={!!errors.addressType && !!touched.addressType}
                    isRequired
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">Address Type</FormLabel>
                    <Select
                      style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                      type="text"
                      name="addressType"
                      color={colorMode == "dark" ? "black": "black"}
                      onBlur={handleBlur}
                      value={values.addressType || ''}
                      onChange={(event) => {
                        setAddressCustomTypeDisable(
                          !(event.target.value === 'Custom'),
                        );
                        setFieldValue('contactCategory', '');
                        setFieldValue('addressType', event.target.value);
                      }}
                    >
                      <option value="" style={{color:"black" , backgroundColor:"white" }}>-Select-</option>
                      <option  style={{color:"black" , backgroundColor:"white" }} value="Home">Home</option>
                      <option  style={{color:"black" , backgroundColor:"white" }} value="Work">Work</option>
                      <option style={{color:"black" , backgroundColor:"white" }} value="Custom">Custom</option>
                    </Select>
                    <FormErrorMessage>{errors.addressType}</FormErrorMessage>
                  </FormControl>
                  {/* <FormControl id="ContactCategory">
                    <FormLabel fontSize="12px" fontFamily="Inter" fontWeight="600" >&nbsp;</FormLabel>
                    <Input
                     fontFamily="Lexend"
                     fontWeight="400"
                      variant="filled"
                      isDisabled={addressCustomTypeDisable}
                      type="text"
                      name="contactCategory"
                      onBlur={handleBlur}
                      value={values.ContactCategory}
                      onChange={(e) => {
                        setFieldValue('ContactCategory', e.target.value);
                      }}
                    />
                  </FormControl> */}
                </HStack>
                <HStack spacing={12}>
                  <FormControl
                    id="addrLine1"
                    isInvalid={!!errors.addrLine1 && !!touched.addrLine1}
                    isRequired
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600" >Address Line 1</FormLabel>
                    <Input
                      style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                     fontFamily="Lexend"
                     fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      disabled
                      type="text"
                      name="addrLine1"
                      onBlur={handleBlur}
                      value={values.addrLine1}
                      onChange={(e) => {
                        setFieldValue('addrLine1', e.target.value);
                      }}
                      placeholder="e.g. addrLine1"
                      _placeholder={{color:"grey"}}
                    />
                    <FormErrorMessage>{errors.addrLine1}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="addrLine2">
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600" >Address Line 2</FormLabel>
                    <Input
                       style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      type="text"
                      onBlur={handleBlur}
                      value={values.addrLine2}
                      onChange={(e) => {
                        setFieldValue('addrLine2', e.target.value);
                      }}
                    />
                  </FormControl>
                </HStack>
                <HStack spacing = {12}>
                  <FormControl
                    id="city"
                    isInvalid={!!errors.city && !!touched.city}
                    isRequired
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">City or Town</FormLabel>
                    <Input
                        style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      type="text"
                      disabled
                      onBlur={handleBlur}
                      name="city"
                      value={values.city}
                      onChange={(e) => {
                        setFieldValue('city', e.target.value);
                      }}
                      placeholder="e.g. city"
                      _placeholder={{color:"grey"}}
                    />{' '}
                    <FormErrorMessage>{errors.city}</FormErrorMessage>
                  </FormControl>

                  <FormControl id="" isRequired>
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">State</FormLabel>
                    <Input
                        style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      type="text"
                      disabled
                      onBlur={handleBlur}
                      name="addrState"
                      value={values.addrState}
                      onChange={(e) => {
                        setFieldValue('addrState', e.target.value);
                      }}
                      placeholder="e.g. State"
                      _placeholder={{color:"grey"}}
                    />
                    {/* <Select
                       
                      type="text"
                      name="addrState"
                      onBlur={handleBlur}
                      value={values.addrState || ''}
                      onChange={(event) => {
                        setFieldValue('addrState', event.target.value);
                      }}
                    >
                      <option value="">-Select-</option>
                      {stateOptions.map((st) => (
                        <option value={st.value} key={st.value}>
                          {st.label}
                        </option>
                      ))}
                    </Select> */}
                  </FormControl>

                  <FormControl
                    id="postCode"
                    isInvalid={!!errors.postCode && !!touched.postCode}
                    isRequired
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">Zip Code</FormLabel>
                    <Input
                        style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      type="text"
                      disabled
                      onBlur={handleBlur}
                      name="postCode"
                      value={values.postCode}
                      onChange={(e) => {
                        setFieldValue('postCode', e.target.value);
                      }}
                      placeholder="e.g. postCode"
                      _placeholder={{color:"grey"}}
                    />{' '}
                    <FormErrorMessage>{errors.postCode}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <FormControl
                    id="referredCode"
                  >
                    <FormLabel fontSize="15.29px" fontFamily="Inter" fontWeight="600">Referred By (Code)</FormLabel>
                    <Input
                        style = {{borderRadius: "60px", borderWidth:"0.382px", borderColor: "gray.200"}}
                       fontFamily="Lexend"
                       fontWeight="400"
                      variant="filled"
                      color={colorMode == "dark" ? "black": "black"}
                      type="text"
                      name="referredCode"
                      onBlur={handleBlur}
                      value={values.referredCode}
                      onChange={(e) => {
                        setFieldValue('referredCode', e.target.value);
                      }}
                      //placeholder="e.g. Michel"
                    />
                  </FormControl>
              </Stack>
            ) : null}
            {/* </Step> */}
            {/* <Step>
              <Stack spacing={4} mt="8" mb='10'> */}
                {/* <h2 color="brand.red">Optional </h2> */}
                {/* <HStack>
                <Text fontSize="md">
                Automatically replace out of stock product in an order with
              similar product
            </Text>
            <Switch size='md' name='orderLineitemReplacement'
            isChecked={values.orderLineitemReplacement}
            onChange={(event) => {
              setFieldValue('orderLineitemReplacement', event.target.checked);
            }} />
            </HStack>

            <HStack>
               <Text fontSize="md">
               Subscribe to notifications/text messages
               </Text>
               <Switch size='md' name='subscribeToNotification' 
               isChecked={values.subscribeToNotification}
               onChange={(event) => {
                 setFieldValue('subscribeToNotification', event.target.checked);
               }} />
               </HStack>
              </Stack>
            </Step> */}
          {/* </Steps> */}

          <Flex justifyContent="center" mt="4" color="gray.500">
        <Text>{`${activeStep + 1}/${2}`}</Text>
      </Flex>

          <Stack pt="12px" spacing={10}>
            <Flex  mt="4">
              <Box>
                <Button
                   fontFamily="Inter"
                   fontWeight="600"
                  variant="ghost"
                  fontSize="15.29px"
                  onClick={() => switchToSignIn()}
                  // borderColor="brand.red"
                  color="brand.red"
                >
                  Back to Login
                </Button>
              </Box>
              <Spacer />
              <Box>
                {activeStep >= 1 ? (
                  <>
                    <Button
                      variant="ghost"
                      fontFamily="Inter"
                      fontWeight="600"
                      color="brand.red"
                      onClick={() => {
                        setShowFinish(false);
                        prevStep(1);
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      fontFamily="Inter"
                      fontWeight="600"
                        ml="2"
                        onClick={() => {
                          if(values.referredCode) {
                            customerPromoCodes().then(data=>{
                            if (validateAddressDetails() && data) {
                              // nextStep(1);
                              setShowFinish(true);
                              handleSubmit();
                            }
                          }
                            ); 
                          }
                          else if(validateAddressDetails()){
                            //nextStep(1);
                            setShowFinish(true);
                            handleSubmit();
                          }
                        }}
                      >
                       Create Account
                      </Button>
                    {/* {showFinish ? (
                      <Button
                      fontFamily="Inter"
                      fontWeight="600"
                      fontSize="14px"
                        isLoading={signupLoading}
                        onClick={() => {
                          handleSubmit();
                        }}
                        ml="2"
                      >
                        Create Account
                      </Button>
                    ) : (
                      <Button
                      fontFamily="Inter"
                      fontWeight="600"
                      fontSize="21.41px"
                        ml="2"
                        onClick={() => {
                          if(values.referredCode) {
                            customerPromoCodes().then(data=>{
                            if (validateAddressDetails() && data) {
                              // nextStep(1);
                              setShowFinish(true);
                              handleSubmit();
                            }
                          }
                            ); 
                          }
                          else if(validateAddressDetails()){
                            //nextStep(1);
                            setShowFinish(true);
                            handleSubmit();
                          }
                        }}
                      >
                       Create Account
                      </Button>
                    )} */}
                  </>
                ) : (
                  <>
                    {activeStep !== 0 ? (
                      <Button
                      fontFamily="Inter"
                      fontWeight="600"
                      fontSize="15.29px"
                      color="brand.red"
                      variant="ghost"
                        onClick={() => {
                          setShowFinish(false);
                          prevStep(1);
                        }}
                      >
                        Previous
                      </Button>
                    ) : (
                      <></>
                    )}

                    <Button
                       fontFamily="Inter"
                       fontWeight="600"
                      ml="2"
                    //   onClick={() => {
                    //   if (formik.isValid){
                    //   nextStep(1);
                    //   }
                    // }}
                      onClick={() => {
                        if (!formik.isValid) {
                          handleDetails()
                        }
                        else{
                          nextStep(1);
                        }
                      }}
                    >
                      {next}
                    </Button>
                  </>
                )}
              </Box>
            </Flex>
          </Stack>
        </form>
      ) : signupState === 'verification' ? (
        <VerifyForm
          username={values.username}
          phoneNumberValue={phoneNum}
          switchToSignIn={switchToSignIn}
        />
      ) : signupState === 'information' ? (
        <Flex direction="column" roundedTopLeft="10" roundedBottomRight="10">
          <VStack textAlign="center" spacing="3" p="10" h="400px" mt="10">
            <Text fontSize="xl">
              We took the privilege of enabling notifications/text messages, 
              You can always change this from your account settings
            </Text>
            <Flex pb="8" direction="row" pt="5">
              <Box>
                <Button
                  type="submit"
                  _hover={{ background: 'brand.red' }}
                  onClick={() => {
                    setSignupState('verification');
                  }}
                  data-cy="verifyNotificationBtn"
                >
                  Ok
                </Button>
              </Box>
            </Flex>
          </VStack>
        </Flex>
      ) : null}
    </Stack>
  );
};

const stateMapper = (state) => ({
  deliveryAddress: state.deliveryAddress?.deliveryAddress,
});

const dispatchMapper = (dispatch) => ({
  signup: dispatch.auth.signUp,
});

export default connect(stateMapper, dispatchMapper)(SignUpForm);
