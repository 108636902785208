/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { BellIcon, CloseIcon, SearchIcon,ChevronDownIcon } from '@chakra-ui/icons';
import * as OrderService from '../../../services/order-service';
import { graphql } from '../../../utils/api';

import '@fontsource/lexend';
import '@fontsource/inter';
import defaultProductImg from '../../../assets/default-product.png';

import {
  deleteCustomerProfile,
} from '../../../graphql/mutations';
import {
  Avatar,
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Image,
  Input,
  SimpleGrid,
  Link,
  MenuGroup,
  MenuDivider,
  VisuallyHidden,
  InputLeftElement,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  VStack,
  Tooltip,
  Select,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  Modal,
  useToast,
  Grid,
  GridItem,
  Divider,
} from '@chakra-ui/react';
import { navigate, useLocation } from '@reach/router';
import { GiPriceTag, } from 'react-icons/gi';
import { FiSearch } from "react-icons/fi"
import { API, Auth, graphqlOperation, Hub } from 'aws-amplify';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState,useMemo } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { HiShoppingCart } from 'react-icons/hi';
import { ImUser } from 'react-icons/im';
import { IoIosContacts, IoMdUnlock } from 'react-icons/io';
import { MdPayment, MdSettings ,MdDelete} from 'react-icons/md';
import { RiLogoutBoxRFill } from 'react-icons/ri';
import { connect } from 'react-redux';
import {
  onRejectionNotificationUpdate,
  onUpdateOrderShipmentNotification,
  onReplaceOrderShipmentNotification,
  onAddOrderShipmentWebhook
} from '../../../graphql/queries';
import {onAddStripeWebhook} from '../../../graphql/queries';
import * as ProductService from '../../../services/product-service';
import { updateDeliveryToId } from '../../../services/user-service';
import { menuListStylesProps } from '../../../utils/stylesProps';
import ChangePasswordView from '../../auth/change-password';
import AddDeliveryContact from '../../home/addDeliveryContact';
import AgeLimit from '../../home/ageLimit';
import Welcome from '../../home/welcome';
import LoginView from '../../login';
import ConfirmationView from '../confirmation-view';
import QuickLinks from '../quickLinks';
import RejectionNotificationView from '../rejection-order-notification-view';
import TopNavigation from '../topNavigation';
import UpdateNotificationView from '../update-order-notification-view';
import awsExports from '../../../aws-exports';
import UpdateDoorDashNotificationView from '../update-doordash-order-notification-view';
import UpdateStripeNotificationView from '../update-stripe-order-notification-view';
import ReplaceOrderShipmentNotificationView from '../replace-ordershipment-notification-view';



export function TopBar({
  user,
  profileImage,
  sessionExpiry,
  cart,
  signOut,
  deliveryAddress,
  cartInvalidFalg,
  deliveryAddressStatus,
  setDeliveryToAddressStatus,
  updateDeliveryAddressStatus,
  fetchAndSetUser,
  setCartInvalidFlag,
}) {
  console.log('user',user)
  const { colorMode, toggleColorMode } = useColorMode();
  const [display, changeDisplay] = useState('none');
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(false);
  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState(false);
  const searchResultsRef = useRef(null);
  const searchInputRef = useRef(null);
  const [ageLimitOpen, setAgeLimitOpen] = useState(false);
  const [isOpen,setIsOpen]=useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hoveredNav, setHoveredNav] = useState(null); 
  const [searchTerm, setSearchTerm] = useState();
  const [searchResultsProducts, setSearchResultsProducts] = useState([]);
  const [searchResultsBrands, setSearchResultsBrands] = useState([]);
  const [cartQty, setCartQty] = useState('0');
  const [updateDeliveryAddressModal, setUpdateDeliveryAddressModal] =
    useState(false);
  const [updateDeliveryToAddress, setUpdateDeliveryToAddress] = useState(false);
  const location = useLocation();
  const [rejectOrderOpen, setRejectOrderOpen] = useState(false);
  const [rejectedOrderDetails, setRejectedOrderDetails] = useState([]);
  const [rejectDoorDashOrderOpen, setRejectDoorDashOrderOpen] = useState(false);
  const [selectedNavLink, setSelectedNavLink] = useState(null);
  const [rejectedDoorDashOrderDetails, setRejectedDoorDashOrderDetails] = useState([]);
  const [updateOrderOpen, setUpdateOrderOpen] = useState(false);
  const [updateDoorDashOrderOpen, setUpdateDoorDashOrderOpen] = useState(false);
  const [updateStripeOrderOpen, setUpdateStripeOrderOpen] = useState(false);
  const [replaceOrderOpen, setReplaceOrderOpen] = useState(false);
  const [updatedOrderDetails, setUpdatedOrderDetails] = useState([]);
  const [updatedDoorDashOrderDetails, setUpdatedDoorDashOrderDetails] = useState([]);
  const [updatedStripeOrderDetails, setUpdatedStripeOrderDetails] = useState([]);
  const [replaceOrderDetails,setReplaceOrderDetails]=useState([])
  const [isOpenmodal, setIsOpenmodal] = useState(false);
  const [viewState, setViewState] = useState('signin');
  const [userObj, setUserObj] = useState({});
  const cancelRef = useRef();
  const [category, setCategory] = useState('All');
  const [countryFlag1,setCountryFlag1] = useState(false);
  const [replaceModalOpen,setReplaceModalOpen]=useState(false)
  // Set initial timeout values (13 minutes and 2 minutes)
  const [alertTimeout, setAlertTimeout] = useState(13 * 60 * 1000);
  const [logoutTimeout, setLogoutTimeout] = useState(2 * 60 * 1000);

  // State to manage the modal visibility
  const [idleModalVisible, setIdleModalVisible] = useState(false);

  // State to store the timestamp of the last user activity
  const [lastActivity, setLastActivity] = useState(Date.now());
  const toast=useToast();
  let countryFlag=false;
  const openModal = () => {
    setIsOpenmodal(true);
    setHoveredNav(null)
    setIsSearchResultsOpen(false)
  };

  const closeModal = () => {
    setIsOpenmodal(false);
    setHoveredNav(null)
      setIsSearchResultsOpen(false)
  };
  const changePasswordConfirmOpenFunc = (bool) => {
    setChangePasswordOpen(bool);
  };

  const onChangePasswordClose = () => {
    setChangePasswordOpen(false);
  };

  const onLoginViewClose = () => {
    setLoginOpen(false);
  };

  const signout = async () => {
    signOut();
    navigate('/');
    setConfirmOpen(false);
  };

  const showError = (data) => {
    console.log("error data", data)
    toast({
      title: 'SignIn Failure',
      description: "error",
      status: 'error',
    });
  }

  const socialLoginViewClose = () => {
    setLoginOpen(false);
    navigate('/');
  };

  const onConfirmClose = () => {
    setConfirmOpen(false);
  };

  const onRejectOrderClose = () => {
    setRejectOrderOpen(false);
  };
  
  const onRejectDoorDashOrderClose = () => {
    setRejectDoorDashOrderOpen(false);
  };

  const onUpdateOrderClose = () => {
    setUpdateOrderOpen(false);
  };
  const onUpdateDoorDashOrderClose = () => {
    setUpdateDoorDashOrderOpen(false);
  };
  const onUpdateStripeOrderClose = () => {
    setUpdateStripeOrderOpen(false);
  };
  const onReplaceOrderclose=()=>{
    setReplaceOrderOpen(false)
  }
  function subtractMonths(date, months) {
    // 👇 Make copy with "Date" constructor
    const dateCopy = new Date(date);
  
    dateCopy.setMonth(dateCopy.getMonth() - months);
    const dateCopy1 = dateCopy.toISOString();
    return dateCopy1;
  }
  const searchProducts = async (searchText,category,countryFlag,user, deliveryAddress,storeName,storeId) => {
    console.log("latitude",user, deliveryAddress)
    const lat = user?.deliveryToAddress?.latitude || deliveryAddress?.lat;
    const lon = user?.deliveryToAddress?.longitude || deliveryAddress?.lon;
    const res = await ProductService.searchGroups(searchText,category,countryFlag);
    const resPnA = await ProductService.searchPnA(searchText,category,countryFlag,lat,lon,storeName,storeId);
    // const res1 = await ProductService.getAvailableProducts("Budweiser");
  //   let data1
  // if(resPnA?.data.items.length>0){
  //   data1=  resPnA?.data?.items.filter((item)=>item.availableQty>0)
  // }
    // setSearchResultsProducts(data1);
    console.log('res1',res);
     setSearchResultsProducts(resPnA.data.items);
    console.log("resPnA", resPnA, lat)
    setSearchResultsBrands(resPnA.data.Brand);
  };
  
  
useEffect(() => {
  // Function to close search results when clicking outside the box
  const handleClickOutside = (event) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setSearchResultsProducts([]);
      setSearchResultsBrands([]);
    }
  };
  // Add event listener for clicks outside the box
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    // Cleanup event listener when component unmounts
    document.removeEventListener('mousedown', handleClickOutside);
  };



}, [searchResultsRef]);

  // const searchProducts1 = async (prodFullName,distance,lat,lon) => {
  //   const res1 = await ProductService.getAvailableProducts(prodFullName,distance,lat,lon);
  //   console.log('res1');
  // };
  // searchProducts1("Budweiser",10,-74.0412,40.7303);
  const storeId = localStorage.getItem('selectedStoreId');
  
  const storeName = localStorage.getItem('selectedStoreName');
  console.log("storeid111",storeId,storeName)
  const debounceSearch = useRef(
    debounce((searchText, searchCategory, countryFlag, user, deliveryAddress) => {
      if (storeId === null && storeName === null) {
        searchProducts(searchText, searchCategory, countryFlag, user, deliveryAddress);
      } else {
        searchProducts(searchText, searchCategory, countryFlag, user, deliveryAddress, storeName, storeId);
      }
    }, 300),
  ).current;

  const onSearchTextChange = (e,user, deliveryAddress) => {
    console.log('onSearchUser',user, deliveryAddress)
    setSearchResultsProducts([]);
    setSearchResultsBrands([]);
    setSearchTerm(e.target.value);
    debounceSearch(e.target.value,category,countryFlag1,user, deliveryAddress );
    console.log("countryFlag::::",countryFlag1);
  };

  function capitalizePhrase(phrase) {
    return phrase
      .split(' ') // Split the sentence into an array of words
      .map((word) => {
        const lowercasedWord = word.toLowerCase(); // Convert the word to lowercase
        return lowercasedWord.charAt(0).toUpperCase() + lowercasedWord.slice(1); // Capitalize the first letter
      })
      .join(' '); // Join the words back into a sentence
  }
  
    
  const handleSearchSubmit = () => {
    setSearchResultsProducts([]);
    setSearchResultsBrands([]);
    const storeId = localStorage.getItem('selectedStoreId');
  
    const storeName = localStorage.getItem('selectedStoreName');
    if (storeName && storeId) {
      console.log("check@123",storeId,storeName)
      // ${encodeURIComponent(searchTerm)}/${storeName}
      window.location.href = `/search/storeInfo/${category}/${searchTerm}/${storeName}`;
    } else {
      window.location.href = `/search/${category}/${encodeURIComponent(searchTerm)}`;
    }
    // window.location.href = `/search/Brand/${capitalizePhrase(searchTerm)}`
    // navigate(`/search/${searchTerm}`);
  };

  const updateDeliveryAddressModalClose = () => {
    setUpdateDeliveryAddressModal(false);
    setUpdateDeliveryToAddress(false);
  };

  const updateDeliveryToAddressId = async () => {
    await updateDeliveryToId(
      user?.userId,
      deliveryAddressStatus?.deliveryAddressId,
    );
    navigate(`/cart/${cart.id}`);
    setUpdateDeliveryAddressModal(false);
    updateDeliveryAddressStatus(null);
  };
  const navigation = [
    {
      navItem: 'Beer',
      navLinks: [
        {
          title: 'BEER',
          links: [
            // { name: 'Alternative', url: '/category/Beer/Alternative' },
            // { name: 'Malt', url: '/category/Beer/Malt' },
            // { name: 'Gluten Free', url: '/category/Beer/Gluten Free' },
            // { name: 'Cider', url: '/category/Beer/Cider' },
            // { name: 'Seltzer', url: '/category/Beer/Seltzer' },
            // { name: 'Imported', url: '/category/Beer/Imported' },
            // { name: 'Ale', url: '/category/Beer/Ale' },
            // { name: 'Low Alcohol', url: '/category/Beer/Low Alcohol' },
            // { name: 'Craft', url: '/category/Beer/Craft' },
            // { name: 'Premium Beer', url: '/category/Beer/Premium Beer' },
            // { name: 'Light Beer', url: '/category/Beer/Light Beer' },
            { name: 'Alcoholic Cider', url: '/category/Beer/Alcoholic Cider' },
            { name: 'Alcoholic Seltzer', url: '/category/Beer/Alcoholic Seltzer' },
            { name: 'Budget/Value', url: `/category/Beer/${encodeURIComponent("Budget/Value")}` },
            { name: 'Coolers/Wine Cocktails', url: `/category/Beer/${encodeURIComponent("Coolers/Wine Cocktails")}`  },
            { name: 'Flavored Malt', url: '/category/Beer/Flavored Malt' },
            { name: 'Hard Cider', url: '/category/Beer/Hard Cider' },
            { name: 'Hard Seltzer', url: '/category/Beer/Hard Seltzer' },
            { name: 'Imports', url: '/category/Beer/Imports' },
            { name: 'Lager', url: '/category/Beer/Lager' },
            { name: 'Malt Liquor', url: '/category/Beer/Malt Liquor' },
            { name: 'Microbrews/Craft', url: `/category/Beer/${encodeURIComponent("Microbrews/Craft")}`  },
            { name: 'Non-Alcoholic', url: '/category/Beer/Non-Alcoholic' },
            { name: 'Other Beer', url: '/category/Beer/Other Beer' },
            { name: 'Premium', url: '/category/Beer/Premium' },
            { name: 'Premium Plus/Super Premium', url: `/category/Beer/${encodeURIComponent("Premium Plus/Super Premium")}` },
            // href={`/Brand/${encodeURIComponent(brandLine)}`




          ],
        },
      ],
    },
    {
      navItem: 'Wine',
      navLinks: [
        {
          title: 'WINE',
          links: [
            // { name: 'Red', url: '/category/Wine/Red' },
            // { name: 'White', url: '/category/Wine/White' },
            // { name: 'Sparkling Wine', url: '/category/Wine/Sparkling' },
            // { name: 'Wine Cocktail', url: '/category/Wine/Cocktail' },
            // { name: 'Rose', url: '/category/Wine/Rose' },
            // { name: 'Flavored Wine', url: '/category/Wine/Flavored' },
            // { name: 'Carbonated Wine', url: '/category/Wine/Carbonated' },
            // { name: 'Blush Wine', url: '/category/Wine/Blush' },
            // { name: 'White - Red Wine', url: '/category/Wine/White - Red' },
            // { name: 'Gift Set', url: '/category/Wine/Gift Set' },
            // { name: 'Ice Wine', url: '/category/Wine/Ice' },
            // { name: 'Mead', url: '/category/Wine/Mead' },
            // { name: 'Port Wine', url: '/category/Wine/Port' },
            // { name: 'Rice Wine', url: '/category/Wine/Rice' },
            // { name: 'Fruit Wine', url: '/category/Wine/Fruit' },
            // { name: 'Sherry Wine', url: '/category/Wine/Sherry' },
            // { name: 'Tonic Wine', url: '/category/Wine/Tonic' },
            // // { name: 'Alcohol Free', url: '/category/Wine/Alcohol Free' },
            // { name: 'Wine Spritzer', url: '/category/Wine/Spritzer' },
            { name: 'Sparkling', url: '/category/Wine/Sparkling' },
            { name: 'Red', url: '/category/Wine/Red' },
            { name: 'Rose', url: '/category/Wine/Rose' },
            { name: 'White', url: '/category/Wine/White' },
            { name: 'Blush', url: '/category/Wine/Blush' },
            { name: 'Rice', url: '/category/Wine/Rice' },
            { name: 'Fruit', url: '/category/Wine/Fruit' },
            { name: 'Brut Sparkling', url: '/category/Wine/Brut Sparkling' },
            { name: 'Port', url: '/category/Wine/Port' },
            { name: 'Red Wine', url: '/category/Wine/Red Wine' },
            { name: 'White Wine', url: '/category/Wine/White Wine' },
            { name: 'Champagne', url: '/category/Wine/Champagne' },
            { name: 'Flavored', url: '/category/Wine/Flavored' },
            { name: 'Gift Set', url: '/category/Wine/Gift Set' },
            { name: 'Ice', url: '/category/Wine/Ice' },
            { name: 'Sherry', url: '/category/Wine/Sherry' },
            // { name: 'Carbonated', url: '/category/Wine/Carbonated' },
            { name: 'Spritzer', url: '/category/Wine/Spritzer' },
            { name: 'Tonic', url: '/category/Wine/Tonic' },
            { name: 'Cocktail', url: '/category/Wine/Cocktail' },
            { name: 'white', url: '/category/Wine/white' },
            { name: 'Mead', url: '/category/Wine/Mead' },
            { name: 'Agave', url: '/category/Wine/Agave' },
            { name: 'red', url: '/category/Wine/red' },
            // { name: 'White - Red', url: '/category/Wine/White - Red' },
            // { name: 'Brut Rose', url: '/category/Wine/Brut Rose' },
          ],
        },
        // {
        //   title: 'Wine Varieties',
        //   links: [
        //     { name: 'Red', url: '/category/Wine/Red' },
        //     { name: 'White', url: '/category/Wine/White' },
        //     { name: 'Sparkling Wine', url: '/category/Wine/Sparkling Wine' },
        //     { name: 'Wine Cocktail', url: '/category/Wine/Wine Cocktail' },
        //     { name: 'Rose', url: '/category/Wine/Rose' },
        //     { name: 'Flavored Wine', url: '/category/Wine/Flavored Wine' },
        //     { name: 'Carbonated Wine', url: '/category/Wine/Carbonated Wine' },
        //     { name: 'Blush Wine', url: '/category/Wine/Blush Wine' },
        //     // { name: 'White - Red Wine', url: '/category/Wine/White - Red Wine' },
        //     // { name: 'Gift Set', url: '/category/Wine/Gift Set' },
        //     // { name: 'Ice Wine', url: '/category/Wine/Ice Wine' },
        //     // { name: 'Mead', url: '/category/Wine/Mead' },
        //     // { name: 'Port Wine', url: '/category/Wine/Port Wine' },
        //     // { name: 'Rice Wine', url: '/category/Wine/Rice Wine' },
        //     // { name: 'Fruit Wine', url: '/category/Wine/Fruit Wine' },
        //     // { name: 'Sherry Wine', url: '/category/Wine/Sherry Wine' },
        //     // { name: 'Tonic Wine', url: '/category/Wine/Tonic Wine' },
        //     // { name: 'Alcohol Free', url: '/category/Wine/Alcohol Free' },
        //     // { name: 'Wine Spritzer', url: '/category/Wine/Wine Spritzer' },
        //   ],
        // },
        // {
        //   title: 'Wine Variety Blends',
        //   links: [
        //     { name: 'Red', url: '/category/Wine/Red' },
        //     { name: 'White', url: '/category/Wine/White' },
        //     { name: 'Sparkling Wine', url: '/category/Wine/Sparkling Wine' },
        //     { name: 'Wine Cocktail', url: '/category/Wine/Wine Cocktail' },
        //     { name: 'Rose', url: '/category/Wine/Rose' },
        //     { name: 'Flavored Wine', url: '/category/Wine/Flavored Wine' },
        //     { name: 'Carbonated Wine', url: '/category/Wine/Carbonated Wine' },
        //     { name: 'Blush Wine', url: '/category/Wine/Blush Wine' },
        //     // { name: 'White - Red Wine', url: '/category/Wine/White - Red Wine' },
        //     // { name: 'Gift Set', url: '/category/Wine/Gift Set' },
        //     // { name: 'Ice Wine', url: '/category/Wine/Ice Wine' },
        //     // { name: 'Mead', url: '/category/Wine/Mead' },
        //     // { name: 'Port Wine', url: '/category/Wine/Port Wine' },
        //     // { name: 'Rice Wine', url: '/category/Wine/Rice Wine' },
        //     // { name: 'Fruit Wine', url: '/category/Wine/Fruit Wine' },
        //     // { name: 'Sherry Wine', url: '/category/Wine/Sherry Wine' },
        //     // { name: 'Tonic Wine', url: '/category/Wine/Tonic Wine' },
        //     // { name: 'Alcohol Free', url: '/category/Wine/Alcohol Free' },
        //     // { name: 'Wine Spritzer', url: '/category/Wine/Wine Spritzer' },
        //   ],
        // },
      ],
    },
    {
      navItem: 'Liquor',
      navLinks: [
        {
          title: 'LIQUOR',
          links: [
            // { name: 'Whiskey', url: '/category/Liquor/Whiskey' },
            // { name: 'Brandy', url: '/category/Liquor/Brandy' },
            // { name: 'Vodka', url: '/category/Liquor/Vodka' },
            // { name: 'Gin', url: '/category/Liquor/Gin' },
            // { name: 'Rum', url: '/category/Liquor/Rum' },
            // { name: 'Tequila', url: '/category/Liquor/Tequila' },
            // { name: 'Cocktails', url: '/category/Liquor/Cocktails' },
            // { name: 'Non Alcoholic', url: '/category/Liquor/Non Alcoholic' },
            // { name: 'Asian Spirits', url: '/category/Liquor/Asian Spirits' },
            // {
            //   name: 'Distilled Spirits',
            //   url: '/category/Liquor/Distilled Spirits',
            // },
            // { name: 'Mezcal', url: '/category/Liquor/Mezcal' },
            // { name: 'Moonshine', url: '/category/Liquor/Moonshine' },
            // { name: 'Flavoured', url: '/category/Liquor/Flavoured' },
            // { name: 'Grain', url: '/category/Liquor/Grain' },
            // { name: 'Liqueur', url: '/category/Liquor/Liqueur' },
            // { name: 'Armagnac', url: '/category/Liquor/Armagnac' },
            // { name: 'Cognac', url: '/category/Liquor/Cognac' },
            // {
            //   name: 'Molecular Spirit',
            //   url: '/category/Liquor/Molecular Spirit',
            // },
            // { name: 'Raicilla', url: '/category/Liquor/Raicilla' },
            // { name: 'Sotol', url: '/category/Liquor/Sotol' },
            // {
            //   name: 'Sugar Cane Spirit',
            //   url: '/category/Liquor/Sugar Cane Spirit',
            // },
            // { name: 'Vermouth', url: '/category/Liquor/Vermouth' },
            // { name: 'Gift Set', url: '/category/Liquor/Gift Set' },
            // { name: 'Mixers', url: '/category/Liquor/Mixers' },
            { name: 'Whiskey', url: '/category/Liquor/Whiskey' },
            { name: 'Brandy', url: '/category/Liquor/Brandy' },
            { name: 'Vodka', url: '/category/Liquor/Vodka' },
            { name: 'Gin', url: '/category/Liquor/Gin' },
            { name: 'Rum', url: '/category/Liquor/Rum' },
            { name: 'Tequila', url: '/category/Liquor/Tequila' },
            { name: 'Cocktails', url: '/category/Liquor/Cocktails' },
            { name: 'Asian Spirits', url: '/category/Liquor/Asian Spirits' },
            // {   name: 'Distilled Spirits', url: '/category/Liquor/Distilled Spirits', },
            { name: 'Mezcal', url: '/category/Liquor/Mezcal' },
            { name: 'Moonshine', url: '/category/Liquor/Moonshine' },
            { name: 'Liqueur', url: '/category/Liquor/Liqueur' },
            { name: 'Armagnac', url: '/category/Liquor/Armagnac' },
            { name: 'Cognac', url: '/category/Liquor/Cognac' },
            // {   name: 'Molecular Spirit',  url: '/category/Liquor/Molecular Spirit', },
            // { name: 'Raicilla', url: '/category/Liquor/Raicilla' },
            { name: 'Sotol', url: '/category/Liquor/Sotol' },
            {   name: 'Sugar Cane Spirit', url: '/category/Liquor/Sugar Cane Spirit',  },
            { name: 'Vermouth', url: '/category/Liquor/Vermouth' },
            { name: 'Gift Set', url: '/category/Liquor/Gift Set' },
            { name: 'Mixers', url: '/category/Liquor/Mixers' },
            { name: 'Grain Alcohol', url: '/category/Liquor/Grain Alcohol' },
            { name: 'Sprits', url: '/category/Liquor/Sprits' },
            { name: 'Bitters', url: '/category/Liquor/Bitters' },
            { name: 'Brandy-Grape', url: '/category/Liquor/Brandy-Grape' },
            { name: 'Gin - Flavored', url: '/category/Liquor/Gin - Flavored' },
            { name: 'Vodka - Flavored', url: '/category/Liquor/Vodka - Flavored' },
            { name: 'Rum - Flavored', url: '/category/Liquor/Rum - Flavored' },
            { name: 'Alcoholic Cocktails', url: '/category/Liquor/Alcoholic Cocktails' },
            { name: 'Brandy - Flavored', url: '/category/Liquor/Brandy - Flavored' },
            { name: 'Bourbon', url: '/category/Liquor/Bourbon' },
            { name: 'Ports and liqueurs', url: '/category/Liquor/Ports and liqueurs' },
            { name: 'Spirits', url: '/category/Liquor/Spirits' },
            { name: 'Vodka gin tequila', url: '/category/Liquor/Vodka gin tequila' },
            { name: 'Tequila - Flavored', url: '/category/Liquor/Tequila - Flavored' },
            { name: 'Tequila & Agave Spirits', url: '/category/Liquor/Tequila & Agave Spirits' },
            { name: 'Whisky-Canadian', url: '/category/Liquor/Whisky-Canadian' },
            { name: 'Scotch', url: '/category/Liquor/Scotch' },
            { name: 'Cognac & Brandy', url: '/category/Liquor/Cognac & Brandy' },
            { name: 'CANADIAN WHISKY', url: '/category/Liquor/CANADIAN WHISKY' },
            { name: 'CORDIAL', url: '/category/Liquor/CORDIAL' },
            { name: 'OTHER WHISKY', url: '/category/Liquor/OTHER WHISKY' },
            { name: 'Other Whisky', url: '/category/Liquor/Other Whisky' },
            { name: 'Blended Whisky', url: '/category/Liquor/Blended Whisky' },
            { name: 'RYE WHISKY', url: '/category/Liquor/RYE WHISKY' },
            { name: 'Rye Whisky', url: '/category/Liquor/Rye Whisky' },
            { name: 'BOURBON', url: '/category/Liquor/BOURBON' },
            { name: 'JAPANESE WHISKY', url: '/category/Liquor/JAPANESE WHISKY' },
            // { name: 'Whisky-Scotch', url: '/category/Liquor/Whisky-Scotch' },
            { name: 'Cognac - Flavored', url: '/category/Liquor/Cognac - Flavored' },
            { name: 'Rye', url: '/category/Liquor/Rye' }, 
            { name: 'Japanese Whisky', url: '/category/Liquor/Japanese Whisky' },
            // { name: 'Other Spirit', url: '/category/Liquor/Other Spirit' },
            // { name: 'Non Alcoholic Cocktails', url: '/category/Liquor/Non Alcoholic Cocktails' },
            { name: 'Asian Spirits', url: '/category/Liquor/Asian Spirits' },
            // { name: 'CORDIALS & LIQUEURS', url: '/category/Liquor/CORDIALS & LIQUEURS' },
            // { name: 'Vermouth - Flavored', url: '/category/Liquor/Vermouth - Flavored' },
            // { name: 'Non-Alcoholic Spirit', url: '/category/Liquor/Non-Alcoholic Spirit' },
            // { name: 'Coganc', url: '/category/Liquor/Coganc' },
            // { name: 'Non-Alcoholic Liqueur', url: '/category/Liquor/Non-Alcoholic Liqueur' },
            // { name: 'Non-Alcoholic Tequila', url: '/category/Liquor/Non-Alcoholic Tequila' },
            // { name: 'MISCELLANEOUS WHISKE', url: '/category/Liquor/MISCELLANEOUS WHISKE' },
            { name: 'Non-Alcoholic Cocktails', url: '/category/Liquor/Non-Alcoholic Cocktails' },
            // { name: 'STRAIGHT RYE', url: '/category/Liquor/STRAIGHT RYE' },
            // { name: 'Grain Neutral Spirit', url: '/category/Liquor/Grain Neutral Spirit' },
            // { name: 'Moonshine - Flavored', url: '/category/Liquor/Moonshine - Flavored' },
            // { name: 'Agave Spirit', url: '/category/Liquor/Agave Spirit' },
            // { name: 'Non-Alcoholic Gin', url: '/category/Liquor/Non-Alcoholic Gin' },
            // { name: 'Spirits-Other', url: '/category/Liquor/Spirits-Other' },
            // { name: 'Non-Alcoholic Cocktail', url: '/category/Liquor/Non-Alcoholic Cocktail' },
            { name: 'vodka', url: '/category/Liquor/vodka' },
            // { name: 'Tequila - Cognac', url: '/category/Liquor/Tequila - Cognac' },
            { name: 'tequila', url: '/category/Liquor/tequila' },
            { name: 'GIN', url: '/category/Liquor/GIN' },
            { name: 'Spirits-based Pre-Mixed Cocktail', url: '/category/Liquor/Spirits-based Pre-Mixed Cocktail' },
            { name: 'COGNAC & BRANDY', url: '/category/Liquor/COGNAC & BRANDY' },
            // { name: 'Scotch Whisky', url: '/category/Liquor/Scotch Whisky' },
            { name: 'LIQUEUR', url: '/category/Liquor/LIQUEUR' },
            { name: 'Spirit', url: '/category/Liquor/Spirit' },
            // { name: 'Canned Cocktail', url: '/category/Liquor/Canned Cocktail' },
            // { name: 'NA Mixer', url: '/category/Liquor/NA Mixer' },
            // { name: 'Sloe Gin', url: '/category/Liquor/Sloe Gin' },
            { name: 'BLENDED WHISKY', url: '/category/Liquor/BLENDED WHISKY' },
            { name: 'BLENDED STRAIGHT WHISKY', url: '/category/Liquor/BLENDED STRAIGHT WHISKY' },
            // { name: 'Non-Alcoholic Vermouth', url: '/category/Liquor/Non-Alcoholic Vermouth' },
            // { name: 'Flavoured Vodka', url: '/category/Liquor/Flavoured Vodka' },
            { name: 'Flavored Vodka', url: '/category/Liquor/Flavored Vodka' },
            // { name: 'SCHNAPPS', url: '/category/Liquor/SCHNAPPS' },
            { name: 'SCOTCH', url: '/category/Liquor/SCOTCH' },
            { name: 'TEQUILA', url: '/category/Liquor/TEQUILA' },
            { name: 'Cordial', url: '/category/Liquor/Cordial' },
            { name: 'Sweetwine Porto bitters', url: '/category/Liquor/Sweetwine Porto bitters' },
            { name: 'Canadian Whisky', url: '/category/Liquor/Canadian Whisky' },
            // { name: 'Non-Alcoholic Rum', url: '/category/Liquor/Non-Alcoholic Rum' },
            { name: 'Non-Alcoholic Whiksey', url: '/category/Liquor/Non-Alcoholic Whiksey' },
          ],
        },
      ],
    },
  ];

  const  handleDeleteAccount = async (_orderType)=>{
  const dateNow= new Date().toISOString();
  const datePrev = subtractMonths(dateNow, 1)
  let limit = 11;
  let nextToken = undefined;
   let filter = {createdAt: {
      between: [datePrev,dateNow]
     }}
    const ModelOrderFilterInput = filter;
    const res = await OrderService.getOrdersByUserId(
      `${user.userId}_${_orderType}`,
      nextToken,
      limit, 
      {
        ...ModelOrderFilterInput
      },
    );
    console.log('res@123',res)
    let count=0;
    res.items.forEach((eachOrderShipment)=>{
     
          if (eachOrderShipment.shipmentStatus==='Placed' || eachOrderShipment.shipmentStatus==='Accepted'){
            count++
            return; 
          }
     
    }) 
    if(count===0){
      setIsOpen(true);
    }
    else{
      toast({
        title: 'Order(s) in Progress.Cancel or wait till orders are fulfilled',
        description: 'To Track orders: Click on profile image -> My Orders',
        status: 'error',
      });
    }
  }
  const deleteUser=async()=>{
    const graphqlQuery = graphqlOperation(deleteCustomerProfile, {
      input: { userId: user?.userId },
    });
    const res = await graphql(graphqlQuery);
    console.log('res@123',res);
    if (res.data.deleteCustomerProfile){
      signOut()
      setIsOpen(false);
    }
  }
  useEffect(async () => {
    const showWelcomePopUp = sessionStorage.getItem('showwelcomepopup');
    if(location.pathname==='/'){
    if (!showWelcomePopUp && awsExports.livemode==='no') {
      setWelcomeOpen(true);
      sessionStorage.setItem('showwelcomepopup', 1);
    }
    const showAgeLimitPopup = localStorage.getItem('showAgeLimitPopup');
    if (!showAgeLimitPopup && awsExports.livemode==='yes' && user != null) {
      setAgeLimitOpen(true);
     
    }
    else if(user === null && !showAgeLimitPopup)
    {
      setAgeLimitOpen(true);
      
    }
   
    else if(user){
      setAgeLimitOpen(false);
    }
  }
    if (location.pathname === '/signin/') {
      // Need to checkthe user already in DynamoDB or not.
      const user = await fetchAndSetUser();
      if (!user) {
        setLoginOpen(true);
        setViewState('socialsignup');
      } else {
        navigate('/');
      }
    }
  }, [user]);

  const calculateTimeSinceLastActivity = useMemo(() => {
    return () => Date.now() - lastActivity;
  }, [lastActivity]);


  const checkTimeouts = useMemo(() => {
    return () => {
      const timeSinceLastActivity = calculateTimeSinceLastActivity();

      // Check if the alert timeout has passed
      if (timeSinceLastActivity >= alertTimeout && !idleModalVisible) {
        // Open the modal after 13 minutes of inactivity
        setIdleModalVisible(true);
      }

      // Check if the logout timeout has passed
      if (timeSinceLastActivity >= (alertTimeout + logoutTimeout)) {
        // Logout the user after an additional 2 minutes of inactivity
        handleLogout(); // Implement your logout logic here
        setIdleModalVisible(false);

      }
    };
  }, [calculateTimeSinceLastActivity, alertTimeout, idleModalVisible, logoutTimeout]);


  useEffect(() => {
   if(user){
    
    const intervalId = setInterval(() => {
      checkTimeouts();
    }, 60000); // Check every minute

    // Add event listeners for user actions
    const resetTimerOnActivity = () => {
      setLastActivity(Date.now());
    };

    window.addEventListener('mousemove', resetTimerOnActivity);
    window.addEventListener('keypress', resetTimerOnActivity);

    // Clear the interval and remove event listeners on component unmount
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('mousemove', resetTimerOnActivity);
      window.removeEventListener('keypress', resetTimerOnActivity);
    };
  }
  
  }, [checkTimeouts,user]);

  useEffect(() => {
    if(user){
     const checkExpiration = () => {
       const currentTime = Date.now();
       console.log('currentTime',currentTime,sessionExpiry)
       if (currentTime >=sessionExpiry) {
         // Logout the user
         handleLogout();
         setLoginOpen(true)
       }
     };
     checkExpiration()
    }
  },[user])

  const handleLogout = () => {
    // Clear any user session and perform necessary logout actions
    signout();
    setIdleModalVisible(false);
    setLoginOpen(true)
  };



  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
    const { event, data } = payload;
    console.log("Payload::",payload)
    console.log('payload->',event,data)

      try {
            switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => {
            Auth.currentSession().then((userInfo) => {
              const user = userInfo?.idToken?.payload;
              setUserObj({
                username: userData.username,
                family_name: user.family_name||"",
                given_name: user.given_name,
                email: user.email,
              });
            });
          });
          break;
        case 'signOut':
          setUserObj(null);
          break;
        case 'oAuthSignOut':
          setUserObj(null);
          signOut()
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failuressss', data);
          if (event === "cognitoHostedUI_failure" && data) {
            showError(data);
          }
          navigate('/');
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error Occured", error);
    }
    });
     // eslint-disable-next-line no-use-before-define
    getUser().then((userData) => {
      if (userData) {
        Auth.currentSession().then((userInfo) => {
          const user = userInfo?.idToken?.payload;
          setUserObj({
            username: userData.username,
            family_name: user.family_name||"",
            given_name: user.given_name,
            email: user.email,
          });
        });
      }
    })     
  
  }, []);

  const getUser = async () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch((error) => {
        console.error('Not signed in',error)
    }
      );
    /*  return Auth.currentUserInfo()
      .then(userData => { console.log('userData----', userData);})
      .catch(() => console.log('Not signed in'));    */
  };

  useEffect(() => {
    if (cart !== null && cart !== undefined && cart.cartShipment.items.length>0) {
      let qty = 0;
      cart.cartShipment.items.forEach((eachCartShipment) => {
        if(eachCartShipment?.lineItems && eachCartShipment.lineItems.length>0){
        eachCartShipment.lineItems.forEach(() => {
          qty++;
        });
      }
      });
      setCartQty(qty);
    } else {
      setCartQty('0');
    }
  }, [cart]);

  const getCustomerContact = async () => {
    // If contact exists then redirect to cart and update the delivery to.
    console.log("intoGetCustomerContact::",deliveryAddress,user,location,deliveryAddressStatus);
    if (
      deliveryAddressStatus?.status === 'exists' &&
      location.pathname === '/'
    ) {
      console.log("intoGetCustomerContact1::",deliveryAddress,user);
      if (
        deliveryAddress.address.latitude ===
          user?.deliveryToAddress?.latitude &&
        deliveryAddress.address.longitude === user?.deliveryToAddress?.longitude
      ) {
        setDeliveryToAddressStatus('same');
        updateDeliveryAddressStatus(null);
      } else if (
        deliveryAddress.address.latitude !==
          user?.deliveryToAddress?.latitude &&
        deliveryAddress.address.longitude !== user?.deliveryToAddress?.longitude
      ) {
        setUpdateDeliveryAddressModal(false);
        setUpdateDeliveryToAddress(true);
        setDeliveryToAddressStatus('notsame');
      }
    }
  };

  useEffect(() => {
    if (
      cart && cart.cartShipment.items.length>0 &&
      deliveryAddress &&
      deliveryAddress?.address &&
      user &&
      user?.deliveryToAddress
    ) {
      getCustomerContact();
    }
    if (user === null || user === undefined || user === '') {
      // updateDeliveryAddressStatus(null);
      setDeliveryToAddressStatus(null);
    }
  }, [cart, deliveryAddress, user, deliveryAddressStatus]);

  useEffect(() => {
    if (user) {
      API.graphql(
        graphqlOperation(onRejectionNotificationUpdate, {
          userId: user?.userId,
        }),
      ).subscribe({
        next: async({ value }) => {
          if(value.data.onRejectionNotificationUpdate.orderId){
          setRejectedOrderDetails({
            id: value.data.onRejectionNotificationUpdate.id,
            orderId: value.data.onRejectionNotificationUpdate.orderId,
            rejectionMsg: value.data.onRejectionNotificationUpdate.rejectionMsg,
          });
          setRejectOrderOpen(true);
          const res = await OrderService.getOrdersByUserId(
            `${user.userId}_Created`,
          );
          const res1 = await OrderService.getOrdersByUserId(
            `${user.userId}_Cancelled`,
          );

        }
        },
        error: (error) => console.log(error),
      });
    }
    if (user) {
      API.graphql(
        graphqlOperation(onUpdateOrderShipmentNotification, {
          userId: user?.userId,
        }),
      ).subscribe({
        next: async({ value }) => {
          console.log("value324:::",value);
          if(value.data.onUpdateOrderShipmentNotification.id){
          setUpdatedOrderDetails({
            id: value.data.onUpdateOrderShipmentNotification.id,
            orderId: value.data.onUpdateOrderShipmentNotification.orderId,
            shipmentStatus: value.data.onUpdateOrderShipmentNotification.shipmentStatus,
          });
          setUpdateOrderOpen(true);
          if(value.data.onUpdateOrderShipmentNotification.shipmentStatus === 'Delivered' || value.data.onUpdateOrderShipmentNotification.shipmentStatus === 'PickedUp'){
          const res = await OrderService.getOrdersByUserId(
            `${user.userId}_Fulfilled`,
          );
          }
          else
          {
            const res = await OrderService.getOrdersByUserId(
              `${user.userId}_Created`,
            );
          }
          }
        },
        error: (error) => console.log(error),
      });
    }
  }, [user]);


  useEffect(() => {
    
    if(user){
          console.log("onAddOrderShipmentWebhookValues:::",user);
          API.graphql(
            graphqlOperation(onAddOrderShipmentWebhook, {
              dropoff_phone_number: user?.phoneNumber.replace(/\s/g,''),
            }),
          ).subscribe({
            next: async({ value }) => {
              console.log("onAddOrderShipmentWebhookValues1:::",value);
    
              // toast({
              //   title: 'Success',
              //   description: `${value.data.onAddOrderShipmentWebhook.event_name} for the orderShipment id ${value.data.onAddOrderShipmentWebhook.external_delivery_id}` ,
              // });
              if(value.data.onAddOrderShipmentWebhook.external_delivery_id){
              setUpdatedDoorDashOrderDetails({
                event_name: value.data.onAddOrderShipmentWebhook.event_name,
                tracking_url: value.data.onAddOrderShipmentWebhook.tracking_url,
                external_delivery_id: value.data.onAddOrderShipmentWebhook.external_delivery_id,
                dropoff_time_estimated: value.data.onAddOrderShipmentWebhook.dropoff_time_estimated,
                dasher_phone_number: value.data.onAddOrderShipmentWebhook.dasher_phone_number,
              });
              setUpdateDoorDashOrderOpen(true)
              // const res = await OrderService.getOrdersByUserId(
              //   `${user.userId}_Created`,
              // );
            }
    
            },
            
    
            error: (error) => console.log("onAddOrderShipmentWebhookValues2:::",error),
          });
        }
      }, [user]);


      useEffect(() => {
    
        if(user){
              console.log("onAddStripeWebhookValues:::",user);
              API.graphql(
                graphqlOperation(onAddStripeWebhook, {
                  email: user?.email,
                }),
              ).subscribe({
                next: async({ value }) => {
                  console.log("onAddStripeWebhookValues1:::",value);
        
                  // toast({
                  //   title: 'Success',
                  //   description: `${value.data.onAddStripeWebhook.event_name} for the Stripe email ${value.data.onAddStripeWebhook.email}` ,
                  // });
                  
                  if(value.data.onAddStripeWebhook.email){
                  setUpdatedStripeOrderDetails({
                    email: value?.data?.onAddStripeWebhook?.email,
                    amount_capturable: value?.data?.onAddStripeWebhook?.amount_capturable,
	                  payment_intent_id: value?.data?.onAddStripeWebhook?.payment_intent_id,
	                  stripe_event_type: value?.data?.onAddStripeWebhook?.stripe_event_type,
                    deliverytype: value?.data?.onAddStripeWebhook?.deliverytype,
                    amount_received: value?.data?.onAddStripeWebhook?.amount_received,
                  });
                  setUpdateStripeOrderOpen(true)
                }
        
                },
                
        
                 error: (error) => console.log("onAddStripeWebhookValues2:::",error),
              });
            }
          }, [user]);
  
          useEffect(() => {
    
            if(user){
                  console.log("onReplaceOrderShipmentNotification:::",user,user.userId);
                  API.graphql(
                    graphqlOperation(onReplaceOrderShipmentNotification, {
                      userId: user?.userId,
                    }),
                  ).subscribe({
                    next: async({ value}) => {
                      
                       console.log("onReplaceOrderShipmentNotification1:::",value);
                       let value1=(value.data.onReplaceOrderShipmentNotification.messageStatus)
                      console.log('value1',value1,typeof value1)
                      setReplaceModalOpen(true)
                     let message1= JSON.parse(value1)
                     setReplaceOrderOpen(true);
                     
                     setReplaceOrderDetails(
                      // id: value1.data.onReplaceOrderShipmentNotification.id,
                       //orderId: value1.data.onReplaceOrderShipmentNotification.orderId,
                       [...message1]
                     );

            
                    },
                    
            
                     error: (error) => console.log("onReplaceOrderShipmentNotification2:::",error),
                  });
                }
              }, [user]);

  useEffect(()=>{
    let tempcartInvalidFlag = false;
    if (user && cart !== null && cart !== undefined && cartInvalidFalg!=true){
      cart.cartShipment.items.forEach((item) => {

        if(
          (item?.deliveryAddress?.latitude!==user?.deliveryToAddress?.latitude &&
          item?.deliveryAddress?.longitude!==user?.deliveryToAddress?.longitude) || item?.lineItems.some((lineItem)=>lineItem.itemInvalid===true)
          )
          tempcartInvalidFlag = true;
      });
      setCartInvalidFlag(tempcartInvalidFlag);
  }
  console.log("IntoTopbar:::",tempcartInvalidFlag);
  }, [user?.userId,cart]);

  return (
    <>
       {isSearchResultsOpen && (
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        background="rgba(0, 0, 0, 0.5)"
        zIndex="999"
      ></Box>
    )}
      <ConfirmationView
        onConfirmClose={onConfirmClose}
        message="Are you sure you want to Signout"
        callbackFunction={signout}
        isOpen={confirmOpen}
        cancelRef={cancelRef}
        submitBtnText="Signout"
      />
      <RejectionNotificationView
        onRejectOrderClose={onRejectOrderClose}
        isRejectOrderOpen={rejectOrderOpen}
        rejectedOrderDetails={rejectedOrderDetails}
      />
      <UpdateNotificationView
        onUpdateOrderClose={onUpdateOrderClose}
        isUpdateOrderOpen={updateOrderOpen}
        updatedOrderDetails={updatedOrderDetails}
      />
      <UpdateDoorDashNotificationView
        onUpdateDoorDashOrderClose={onUpdateDoorDashOrderClose}
        isUpdateDoorDashOrderOpen={updateDoorDashOrderOpen}
        updatedDoorDashOrderDetails={updatedDoorDashOrderDetails}
        setUpdateDoorDashOrderOpen={setUpdateDoorDashOrderOpen}
        isOpen={updateDoorDashOrderOpen}
      />
      <UpdateStripeNotificationView
        onUpdateStripeOrderClose={onUpdateStripeOrderClose}
        isUpdateStripeOrderOpen={updateStripeOrderOpen}
        updatedStripeOrderDetails={updatedStripeOrderDetails}
        setUpdateStripeOrderOpen={setUpdateStripeOrderOpen}
        isOpen={updateStripeOrderOpen}
      />
      <ReplaceOrderShipmentNotificationView
        onReplaceOrderclose={onReplaceOrderclose}
        replaceOrderOpen={replaceOrderOpen}
        replaceOrderDetails={replaceOrderDetails}
        replaceModalOpen={replaceModalOpen}
        setReplaceModalOpen={setReplaceModalOpen}
        setReplaceOrderDetails={setReplaceOrderDetails}
        // setReplaceOrderOpen={setReplaceOrderOpen}
      />
       
      
      <Box
        zIndex="999"
        
        sx={{
          position: 'sticky',
          top: '0',
        }}
      >
        <QuickLinks />
        <Box px={6} m="auto" className="mainBg" bg={colorMode==='light' ? 'white' : 'black' }>
          <Flex
          // height='40px'
             minH="60px"
            align="center"
            direction="row"
            flexGrow="2"
            justifyContent="space-between"
          >
            <Tooltip
              hasArrow
              label="Home / Dashboard"
              bg="brand.red"
              color="White"
            >
              <Box>
                <Button
                  width={{base:'100px' , md:"160px" , lg :"180px"}}
                  variant="link"
                  onClick={() => {
                    localStorage.removeItem('selectedStoreName');
                    localStorage.removeItem('selectedStoreId');
                    navigate('/');
                  }}
                >
                  <Image  width={{base:'100px' , md:"160px" , lg :"180px"}} src={require('../../../assets/logo/newlogo-small.svg')} />
                </Button>
              </Box>
            </Tooltip>
            <Flex alignItems="center"  w="100%"   justifyContent="space-evenly" position='relative' >
            <HStack
               as="nav"
               spacing= '5px'
               display={['none', 'none', 'none', 'flex']}
               >
               {navigation.map((nav) => (
                <Menu key={nav.navItem} 
                // autoSelect={false}
                 className="mainBg" 
                isOpen={hoveredNav === nav.navItem}
                onOpen={() => setHoveredNav(nav.navItem)}
                onClose={() => setHoveredNav(null)}
                >
                  <MenuButton
                  fontFamily="Lexend"
                  fontWeight="semibold" 
                    width="90px"
                    fontSize="lg"
                    rounded="lg"
                    // pl="4px"
                    onMouseEnter={() => {setHoveredNav(nav.navItem)
                      setIsSearchResultsOpen(true)}}
                    onMouseLeave={() => {setHoveredNav(null)
                      setIsSearchResultsOpen(false)}}
                    _hover={{
                      color: 'brand.red',
                      borderColor: 'brand.red',
                    }}
                    // onClick={()=>navigate(`/category/${nav.navItem}`)}
                  >
                  {/* <Text
                    fontWeight="bold"
                    fontSize="lg"
                    rounded="lg"
                    px="4px"
                    // onMouseEnter={() => setHoveredNav(nav.navItem)}
                    // onMouseLeave={() => setHoveredNav(null)}
                    // _hover={{
                    //   color: 'brand.red',
                    //   borderColor: 'brand.red',
                    // }}
                    // onClick={()=>navigate(`/category/${nav.navItem}`)}
                  > */}
                    {nav.navItem} 
                    <ChevronDownIcon color={colorMode==='light'?'black':'White'}/>
                     {/* </Text>  */}
                  </MenuButton>    
                  <MenuList
  style={{ borderRadius: "0px 0px 20px 20px", marginTop: `${(deliveryAddress || user) ? '1px' : '1px'}` }}
  onMouseEnter={() => {
    setHoveredNav(nav.navItem);
    setIsSearchResultsOpen(true);
  }}
  onMouseLeave={() => {
    setHoveredNav(null);
    setIsSearchResultsOpen(false);
  }}
  px="5"
>
  {nav.navLinks.map((navLink) => (
    <MenuItem
      key={navLink.title}
      _hover={{ bg: 'none' }}
      onClick={(e) => e.stopPropagation()}
    >
      <Flex direction={{ lg: 'row', base: 'column' }}>
        <Box mb="15px" mt="10px">
          <Box
            fontWeight="600"
            fontSize="13px"
            fontFamily="Inter"
            mb="24px"
            cursor="pointer"
            width="auto" 
            maxWidth="150px"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            onClick={(e) => {
              e.stopPropagation(); 
              e.preventDefault(); 

              if (storeName) {
                navigate(`/category/${navLink.title.toLowerCase()}/storeInfo/${storeName}`);
              } else {
                navigate(`/category/${navLink.title.toLowerCase()}`);
              }
              console.log('onclickkkk', navLink.title, localStorage.getItem('localFilters'));
              if (localStorage.getItem('localFilters')) {
                localStorage.removeItem('localFilters');
              }
            }}
          >
            {navLink.title}
          </Box>

          {/* Links inside category */}
          {navLink.links && navLink.links.length > 0 ? (
            <SimpleGrid
              columns={navLink.links.length > 25 ? 5 : Math.round(navLink.links.length / 5)}
              spacingX="12px"
              mt="4"
              mb="-8"
            >
              {navLink.links.slice(0, 25).map((link, index) => (
                <Box
                  w="150px"
                  h="40px"
                  alignItems="center"
                  justifyContent="center"
                  borderRight={(index + 1) % Math.round(navLink.links.length / 5) !== 0 ? '1px' : 'none'}
                  borderColor="gray.200"
                  key={link.name}
                >
                  <Link
                    href={storeName ? `${link.url}/storeInfo/${storeName}` : link.url}
                    fontFamily="Inter"
                    fontSize="13px"
                    fontWeight="400"
                    alignItems="center"
                    justifyContent="center"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent propagation
                      if (localStorage.getItem('localFilters')) {
                        localStorage.removeItem('localFilters');
                      }
                    }}
                  >
                    {link.name}
                  </Link>
                </Box>
              ))}
            </SimpleGrid>
          ) : null}

          {navLink.subNavLinks ? (
            <MenuGroup title={navLink.title}>
              <MenuDivider />
              {navLink.subNavLinks.map((subNavLink) => (
                <MenuItem
                  key={subNavLink.title}
                  onClick={(e) => e.stopPropagation()} 
                >
                  <Text fontWeight="bold">{subNavLink.title}</Text>
                  <SimpleGrid
                    columns={Math.round(subNavLink.links.length / 5)}
                    spacingX="30px"
                    spacingY="10px"
                    mt="4"
                  >
                    {subNavLink.links.map((link) => (
                      <Link
                        key={link.name}
                        href={link.url}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent propagation
                          if (localStorage.getItem('localFilters')) {
                            localStorage.removeItem('localFilters');
                          }
                        }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </SimpleGrid>
                </MenuItem>
              ))}
            </MenuGroup>
          ) : null}

          <Box mb="15px" mt="40px" p="0 15px">
            {navLink.links && navLink.links.length > 25 ? (
              <>
                <Text
                  fontWeight="600"
                  fontSize="13px"
                  fontFamily="Inter"
                  mt="80px" 
                  mb="20px"
                  textAlign="center"
                  cursor="pointer"
                  width="auto" 
                  maxWidth="150px" 
                  margin="0 auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedNavLink(navLink); 
                    openModal();
                  }}
                >
                  View more
                </Text>

                {selectedNavLink && (
                  <Modal isOpen={isOpenmodal} onClose={closeModal}>
                    <ModalOverlay />
                    <ModalContent
                      maxW="60%" 
                      maxH="80%"
                      margin="0 auto" // Center modal
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ModalHeader>{selectedNavLink.title}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody overflowY="auto">
                        <SimpleGrid columns={5} spacingX="12px" mt="4" mb="-8">
                          {selectedNavLink.links.map((link, index) => (
                            <Box
                              w="150px"
                              h="40px"
                              alignItems="center"
                              justifyContent="center"
                              borderRight={(index + 1) % Math.round(selectedNavLink.links.length / 5) !== 0 ? '1px' : 'none'}
                              borderColor="gray.200"
                              key={link.name}
                              onClick={(e) => e.stopPropagation()} 
                            >
                              <Link
                                href={link.url}
                                fontFamily="Inter"
                                fontSize="11px"
                                fontWeight="400"
                                alignItems="center"
                                justifyContent="center"
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  if (localStorage.getItem('localFilters')) {
                                    localStorage.removeItem('localFilters');
                                  }
                                }}
                              >
                                {link.name}
                              </Link>
                            </Box>
                          ))}
                        </SimpleGrid>
                      </ModalBody>
                      <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={closeModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                )}
              </>
            ) : null}
          </Box>

        </Box>
      </Flex>
    </MenuItem>
  ))}
</MenuList>





              </Menu>
               ))}
                
              </HStack>
              </Flex>
              <Flex  >
              <VStack
               
               w="100%"
               display={{ base: 'none',sm:"flex", md: 'flex' }}
                           >
                            <Flex  alignItems="center"  w="100%"   justifyContent="space-evenly" position='relative'>
                            <InputGroup borderRadius="full"  width={{base:"185px",md:"250px" ,lg:"320px" , xl:"380px"}}  >      
              
               <InputLeftElement
                   
                   pointerEvents="none"
                   fontSize="lg"
                   children={<FiSearch color="black" />}
                 />
                 <Input
                   ref={searchInputRef}
                    fontFamily='Inter'
                   variant="outline"
                   bg="#fff !important"
                   width="100%"
                   borderRadius={40}
                   //color={colorMode === 'light' ? 'black' : 'white'}
                   color="black"
                   type="search"
                  placeholder="Search Products"
                  _placeholder={{color:'grey'}}
                  onClick={e=>onSearchTextChange(e,user, deliveryAddress)}
                   onChange={e=>onSearchTextChange(e,user, deliveryAddress)}
                  
                   onKeyPress={(event) => {
                     if (event.key === 'Enter') {
                      if(localStorage.getItem('localFilters')){
                        localStorage.removeItem('localFilters')
                      }
                       handleSearchSubmit();
                     }
                   }}
                   name="search"
                   autoComplete="off"
                   data-cy="searchInput"
                 />
                 
                   {/* <IconButton
                     onClick={handleSearchSubmit}
                     aria-label="Search"
                     type=""
                     bg="brand.red"
                     style={{ minWidth: '40px', height: '100%' }}
                     icon={<SearchIcon color="#D6D7E3" fontSize="xl" />}
                   /> */}
                 
               </InputGroup>
               </Flex>
               {/* <InputRightAddon
                   onClick={handleSearchSubmit}
                   bg="brand.red"
                   pointerEvents="none"
                   roundedRight="lg"
                   children={<SearchIcon color="#D6D7E3" fontSize="xl" />}
                 /> */}

               {(searchResultsProducts || searchResultsBrands) && (searchResultsProducts?.length > 0 || searchResultsBrands?.length>0) ? (
                <>
             
                 <Box
                    ref = {searchResultsRef}
                   alignItems="right"
                   position="absolute"
                   top={user || deliveryAddress ? "60px" : "80px"}
                   left='150px'
                   className="blockBg searchScrollBar"
                   style={{ border: '1px solid #B2B2B2' , borderRadius: "0px 0px 20px 20px" ,  marginTop: `${(deliveryAddress || user) ? '42.5px' : '18px'}`}}
                   p="5"
                   w="70%"
                   zIndex="99999"
                   maxH="70vh" 
                   overflowY="auto"   
                 >
                   
                  <Grid templateColumns="2fr 0.1fr 1fr" gap="4">
                    <Grid templateColumns="1fr 1fr" gap={{ base: 4, md: 8 }} >
                      <GridItem fontWeight='600' fontFamily='Inter' fontSixe='13px' >PRODUCT MATCHES</GridItem>
                      <GridItem></GridItem>
                    {/* <ListItem fontWeight='600' fontFamily='Inter' fontSixe='13px'>PRODUCTS</ListItem> */}
                     {searchResultsProducts.length>0 ?<>
                      {searchResultsProducts.slice(0,8).map((eachResult) => (
                        //  const tempImagePath = eachResult?.imageFile
                        //  ? `${awsExports.aws_s3_imagesUrl}/product/${eachResult?.imageFile}`
                        //  : defaultProductImg;
                       <GridItem key={eachResult.prodFullName} fontSize ='13px' 
                       fontFamily = 'Inter'
                       fontWeight ='600' mb='0'
                       css={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 3, // Adjust the number of lines as needed
                      }}>
                          {/* <a href={
            eachResult.prodCategory && (eachResult.prodCategory === 'Liquor' || eachResult.prodCategory === 'Wine') 
              ? `/products/${eachResult.prodCategory}/${encodeURIComponent(eachResult.prodFullName)}/${encodeURIComponent(eachResult.productName)}/${eachResult.sizeUom}/${eachResult.storeItemQty}/${encodeURIComponent(searchTerm)}`
              : `/products/${eachResult.prodCategory}/${encodeURIComponent(eachResult.prodFullName)}/${encodeURIComponent(eachResult.productName)}/${eachResult.sizeUom}/${eachResult.containerType}/${eachResult.storeItemQty}/${encodeURIComponent(searchTerm)}`
          }>
                        <HStack>
                          <Image width="75px" height="90px" src=
                          { eachResult?.imageFile 
                         ? (/^\d{14}-/.test(eachResult?.imageFile) 
                         ? `${awsExports.aws_s3_imagesUrl}/salsify/${eachResult?.imageFile}` 
                         : `${awsExports.aws_s3_imagesUrl}/product/${eachResult?.imageFile}`
                       )
                     : defaultProductImg  
                     }
                     onError={(e) => {
                      e.target.onerror = null;  // Prevent infinite loop in case of fallback image also fails
                      e.target.src = defaultProductImg;
                    }}
                     ></Image>
                          <VStack alignItems="flex-start">
                            <Text>
                           {eachResult.prodFullName}
                           </Text>
                         <Text fontSize='12px'  fontWeight="400" fontFamily='Inter'> {eachResult.brandLine}</Text>
                         <Text fontSize='14px' fontWeight="600" fontFamily='Inter'> ${eachResult.price}</Text>
                         </VStack>
                         </HStack>
                         </a> */}
                         { storeName ? (
                          
                          <a href={
                           eachResult.prodCategory && (eachResult.prodCategory === 'Liquor' || eachResult.prodCategory === 'Wine') 
                             ? `/products/${eachResult.prodCategory}/${encodeURIComponent(eachResult.prodFullName)}/${encodeURIComponent(eachResult.productName)}/${eachResult.sizeUom}/${eachResult.storeItemQty}/${encodeURIComponent(searchTerm)}/storeInfo/${storeName}`
                             : `/products/${eachResult.prodCategory}/${encodeURIComponent(eachResult.prodFullName)}/${encodeURIComponent(eachResult.productName)}/${eachResult.sizeUom}/${eachResult.containerType}/${eachResult.storeItemQty}/searchKeyword/${encodeURIComponent(searchTerm)}/storeInfo/${storeName}`
                         }
                        >
                                       <HStack>
                                         <Image width="75px" height="90px" src=
                                         { eachResult?.imageFile 
                                        ? (/^\d{14}-/.test(eachResult?.imageFile) 
                                        ? `${awsExports.aws_s3_imagesUrl}/salsify/${eachResult?.imageFile}` 
                                        : `${awsExports.aws_s3_imagesUrl}/product/${eachResult?.imageFile}`
                                      )
                                    : defaultProductImg  
                                    }
                                    onError={(e) => {
                                     e.target.onerror = null;  // Prevent infinite loop in case of fallback image also fails
                                     e.target.src = defaultProductImg;
                                   }}
                                    ></Image>
                                         <VStack alignItems="flex-start">
                                           <Text>
                                          {eachResult.prodFullName}
                                          </Text>
                                        <Text fontSize='12px'  fontWeight="400" fontFamily='Inter'> {eachResult.brandLine}</Text>
                                        <Text fontSize='14px' fontWeight="600" fontFamily='Inter'> ${eachResult.price}</Text>
                                        </VStack>
                                        </HStack>
                                        </a>
                       ) :   <a href={
                         eachResult.prodCategory && (eachResult.prodCategory === 'Liquor' || eachResult.prodCategory === 'Wine') 
                           ? `/products/${eachResult.prodCategory}/${encodeURIComponent(eachResult.prodFullName)}/${encodeURIComponent(eachResult.productName)}/${eachResult.sizeUom}/${eachResult.storeItemQty}/${encodeURIComponent(searchTerm)}`
                           : `/products/${eachResult.prodCategory}/${encodeURIComponent(eachResult.prodFullName)}/${encodeURIComponent(eachResult.productName)}/${eachResult.sizeUom}/${eachResult.containerType}/${eachResult.storeItemQty}/${encodeURIComponent(searchTerm)}`
                       }
                      >
                         
                                     <HStack>
                                       <Image width="75px" height="90px" src=
                                       { eachResult?.imageFile 
                                      ? (/^\d{14}-/.test(eachResult?.imageFile) 
                                      ? `${awsExports.aws_s3_imagesUrl}/salsify/${eachResult?.imageFile}` 
                                      : `${awsExports.aws_s3_imagesUrl}/product/${eachResult?.imageFile}`
                                    )
                                  : defaultProductImg  
                                  }
                                  onError={(e) => {
                                   e.target.onerror = null;  // Prevent infinite loop in case of fallback image also fails
                                   e.target.src = defaultProductImg;
                                 }}
                                  ></Image>
                                       <VStack alignItems="flex-start">
                                         <Text>
                                        {eachResult.prodFullName}
                                        </Text>
                                      <Text fontSize='12px'  fontWeight="400" fontFamily='Inter'> {eachResult.brandLine}</Text>
                                      <Text fontSize='14px' fontWeight="600" fontFamily='Inter'> ${eachResult.price}</Text>
                                      </VStack>
                                      </HStack>
                                      </a>
                     }
                         
                         {/* <Text>{eachResult.title}</Text> */}
                       </GridItem>
                     ))}</>:<>{searchResultsProducts.map((eachResult) => (
                      <GridItem key={eachResult.prodFullName} fontSize ='13px' 
                      fontFamily = 'Inter'
                      fontWeight ='400' mb='0'>
                        <a href={`/products/${eachResult.prodCategory}/${eachResult.prodFullName}/${eachResult.productName}/${eachResult.sizeUom}/${encodeURIComponent(searchTerm)}`} onClick={()=>localStorage.removeItem('localFilters')}>
                          {eachResult.prodFullName}
                        </a>
                        {/* <Text>{eachResult.title}</Text> */}
                      </GridItem>
                    ))}</>}
                     
                   
                    </Grid>
                    <Divider orientation="vertical" borderColor="gray.300" height="100%" />
                  <List spacing={5} pl={5}>
                     {/* <ListItem>Search Suggestions</ListItem> */}
                     <ListItem fontWeight='600' fontFamily='Inter' fontSixe='13px' >BRAND SUGGESTIONS</ListItem>
                     {searchResultsBrands.length > 6 ?<> {searchResultsBrands.slice(0,6).map((eachResult) => (
                       <ListItem 
                         key={eachResult.key} 
                         fontSize ='13px' 
                         fontFamily = 'Inter'
                         fontWeight ='400'
                        
                         // _hover = {{ background: 'grey' }}
                         
                       >
                         {/* <a href={`/product/${eachResult.categoryName}/${eachResult.title}`}> */}
                         <HStack>
                         { storeName ? (
                              <a
                                href={`/Brand/${eachResult.key}/${storeName}`}
                                onClick={() => {
                                  console.log("OnClick Brand::");
                                  localStorage.removeItem('localFilters');
                                  searchProducts(null, null, countryFlag, user, deliveryAddress, storeName, storeId);
                                  // localStorage.removeItem('selectedStoreName');
                                  // localStorage.removeItem('selectedStoreId');
                                }}
                              >
                                {eachResult.key}
                              </a>
                            ) :  <a href={ `/Brand/${eachResult.key}`}
                            onClick={()=>{
                            console.log("OnClick Brand::")
                            localStorage.removeItem('localFilters')
                            }}>
                              {eachResult.key} 
                            </a>
                          }
                         {/* <Text fontSize ='12' align = 'box' fontWeight='bold' color='grey' >
                           {eachResult.doc_count} options
                           </Text> */}
                         </HStack>
                       </ListItem>
                     ))}</> :<> {searchResultsBrands.map((eachResult) => (
                      <ListItem 
                        key={eachResult.key} 
                        fontSize ='13px' 
                        fontFamily = 'Inter'
                        fontWeight ='400'
                       
                        // _hover = {{ background: 'grey' }}
                        
                      >
                        {/* <a href={`/product/${eachResult.categoryName}/${eachResult.title}`}> */}
                        <HStack>
                        { storeName ? (
                                <a
                                  href={`/Brand/${eachResult.key}/${storeName}`}
                                  onClick={() => {
                                    console.log("OnClick Brand::");
                                    localStorage.removeItem('localFilters');
                                    searchProducts(null, null, countryFlag, user, deliveryAddress, storeName, storeId);
                                    // localStorage.removeItem('selectedStoreName');
                                    // localStorage.removeItem('selectedStoreId');
                                  }}
                                >
                                  {eachResult.key}
                                </a>
                              ) :  <a href={` /Brand/${eachResult.key}`}
                              onClick={()=>{
                              console.log("OnClick Brand::")
                              localStorage.removeItem('localFilters')
                              }}>
                                {eachResult.key} 
                              </a>
                            }
                        {/* <Text fontSize ='12' align = 'box' fontWeight='bold' color='grey' >
                          {eachResult.doc_count} options
                          </Text> */}
                        </HStack>
                      </ListItem>
                    ))}</>}
                    
                   </List>
                   <GridItem colSpan={3} textAlign="center">
    <Box mt="20px" justifyContent="center" fontSize="14px">
      <Link fontWeight='400' fontFamily='Inter' fontSixe='12px' onClick={handleSearchSubmit}>View all results for <Text as="span" fontWeight="bold">{searchTerm}</Text></Link>
      
    </Box>
  </GridItem>
                  </Grid>
                 
                 
                  
                 </Box>
                 <Box
                  position="fixed"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  background="rgba(0, 0, 0, 0.5)"
                  zIndex="-1"
                ></Box>
                </>
               ) : (
                 <></>
               )}
             </VStack>
              </Flex>
            <Flex alignItems="center" justifyContent="" w="100%">
            
             
              
            </Flex>

            <Flex direction="row" sx={{ alignItems: 'center' }}>
              <Box>
                {user ? (
                  <Flex direction="row">
                    <Box
                      alignItems="center"
                      display={{ base: 'none', md: 'flex' }}
                      mr="3"
                    >
                      <Text noOfLines={1} isTruncated title={user?.firstName}>
                        {user?.firstName}
                      </Text>
                    </Box>
                    <Menu>
                      <Tooltip
                        hasArrow
                        label="Profile / Settings"
                        bg="brand.red"
                        color="White"
                      >
                        <MenuButton
                          as={Button}
                          rounded="full"
                          variant="link"
                          cursor="pointer"
                          width="50px"
                          height="50px"
                          data-cy="userMenuBtn"
                        >
                          <Avatar
                            name={user?.firstName}
                            bg="red.500"
                            src={
                              profileImage ||
                              require('../../../assets/defaultUser.png')
                            }
                          />
                        </MenuButton>
                      </Tooltip>
                      <MenuList {...menuListStylesProps} w="200px">
                        <MenuItem
                          _hover={{ bg: 'brand.red' }}
                          _focus={{ bg: 'brand.red' }}
                          onClick={() => {
                            navigate('/userprofile/');
                          }}
                          icon={<ImUser fontSize="16px" />}
                        >
                          My Profile
                        </MenuItem>
                        {user && !user.isSocialLogin && (
                          <MenuItem
                            _hover={{ bg: 'brand.red' }}
                            _focus={{ bg: 'brand.red' }}
                            onClick={() => {
                              changePasswordConfirmOpenFunc(true);
                            }}
                            icon={<IoMdUnlock fontSize="16px" />}
                          >
                            Change Password
                          </MenuItem>
                        )}
                        <MenuItem
                          _hover={{ bg: 'brand.red' }}
                          _focus={{ bg: 'brand.red' }}
                          onClick={() => {
                            navigate('/userprofile/deliverycontacts');
                          }}
                          icon={<IoIosContacts fontSize="16px" />}
                        >
                          Delivery Contacts
                        </MenuItem>
                        {/* <MenuItem
                          _hover={{ bg: 'brand.red' }}
                          _focus={{ bg: 'brand.red' }}
                          onClick={() => {
                            navigate('/userprofile/payments');
                          }}
                          icon={<MdPayment fontSize="16px" />}
                        >
                          Payments
                        </MenuItem> */}

                        <MenuItem
                          _hover={{ bg: 'brand.red' }}
                          _focus={{ bg: 'brand.red' }}
                          onClick={() => {
                            navigate('/userprofile/myorders');
                          }}
                          icon={<MdSettings fontSize="16px" />}
                          data-cy="myOrderMenuItem"
                          
                    sx={{
                      '::placeholder': {
                          color: 'gray', // Set the placeholder color to gray
                      },
                  }}
                        >
                          My Orders
                        </MenuItem>
                        <MenuItem
                          _hover={{ bg: 'brand.red' }}
                          _focus={{ bg: 'brand.red' }}
                          onClick={()=>{
                            handleDeleteAccount('Created')
                          }}
                          icon={<MdDelete fontSize="16px" />}
                          data-cy="myOrderMenuItem"
                        >
                          Delete My Account
                        </MenuItem>
                        {/* <MenuItem
                          _hover={{ bg: 'brand.red' }}
                          icon={<MdSettings fontSize="16px" />}
                          onClick={() => {
                            navigate('/userprofile/settings');
                          }}
                        >
                          Settings */}
                        
                        <MenuItem
                          icon={<RiLogoutBoxRFill fontSize="16px" />}
                          _hover={{ bg: 'brand.red' }}
                          onClick={() => {
                            setConfirmOpen(true);
                          }}
                        >
                          Sign Out
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                ) : null}
              </Box>
              <SearchIcon
                aria-label="Open Menu"
                mr={2}
                style={{ fontSize: '20px', margin: 'auto' }}
                onClick={() => changeDisplay('flex')}
                display={['flex', 'none', 'none', 'none']}
              />

              <Flex
                w="100vw"
                display={display}
                bgColor="gray.50"
                p="5"
                pr="10"
                h="100vh"
                pos="fixed"
                top="20"
                left="0"
                zIndex={20}
                overflowY="auto"
                flexDir="column"
              >
                <Flex justify="flex-end">
                  <CloseIcon size="lg" onClick={() => changeDisplay('none')} />
                </Flex>

                <Flex
                  flexDir="column"
                  align="center"
                  mt="2"
                  position="relative"
                >
                  <InputGroup>
                    <Input
                      variant="outline"
                      type="search"
                      bg="#fff"
                      color="#000"
                      placeholder="Search for Products"
                      onChange={onSearchTextChange}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          handleSearchSubmit();
                        }
                      }}
                      name="search"
                      autoComplete="off"
                    />
                    <InputRightElement borderRadius="16px">
                      <IconButton
                        onClick={handleSearchSubmit}
                        aria-label="Search"
                        bg="brand.red"
                        style={{ minWidth: '40px', height: '100%' }}
                        icon={<SearchIcon color="#D6D7E3" fontSize="xl" />}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {(searchResultsProducts || searchResultsBrands) && (searchResultsProducts?.length > 0 || searchResultsBrands?.length>0) ? (
                  <Box
                    alignItems="right"
                    position="absolute"
                    top="27px"
                    left="0px"
                    className="mainBg"
                    style={{ border: '1px solid #ACABAB' }}
                    p="5"
                    w="100%"
                    zIndex="99999"
                    maxH="400px" 
                    overflowY="auto"
                  >
                    <List spacing={3} background = 'grey.300'>
                      <ListItem>Search Suggestions</ListItem>
                      <ListItem fontWeight='bold'>BRANDS</ListItem>
                      {searchResultsBrands.map((eachResult) => (
                        <ListItem 
                          key={eachResult.key} 
                          fontSize ='12' 
                          // _hover = {{ background: 'grey' }}
                          
                        >
                          {/* <a href={`/product/${eachResult.categoryName}/${eachResult.title}`}> */}
                          <HStack>
                          <a href={ `/Brand/${eachResult.key}`}>
                            {eachResult.key} 
                          </a>
                          {/* <Text fontSize ='12' align = 'box' fontWeight='bold' color='grey' >
                            {eachResult.doc_count} options
                            </Text> */}
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                    <List spacing={3} pt ='3'>
                      <ListItem fontWeight='bold'>PRODUCTS</ListItem>
                      {searchResultsProducts.map((eachResult) => (
                        <ListItem key={eachResult.prodFullName} fontSize ='12'>
                          <a href={`/products/${eachResult.prodCategory}/${eachResult.prodFullName}`}>
                            {eachResult.prodFullName}
                          </a>
                          {/* <Text>{eachResult.title}</Text> */}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <></>
                )}
                </Flex>
              </Flex>
              <Tooltip
                hasArrow
                label={colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
                bg="brand.red"
                color="White"
              >
                <IconButton
                  size="md"
                  alignItems="center"
                  fontSize="xl"
                  aria-label="Switch to mode"
                  variant="ghost"
                  color="current"
                  ml={{ base: '0', md: '3' }}
                  onClick={toggleColorMode}
                  icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
                />
              </Tooltip>
              {/* <Box
                alignItems="center"
                display={{ base: 'none', md: 'flex' }}
                ml="2"
              >
                <Tooltip
                  hasArrow
                  label="Notifications"
                  bg="brand.red"
                  color="White"
                >
                  <BellIcon fontSize="2xl" />
                </Tooltip>
              </Box> */}
              <Box   alignItems="center"
                display="flex"
                ml="2"
                cursor="pointer"
                position="relative">
              {user ? null : (
                <Box mr="15px">
                  <Button
                  fontFamily="Lexend"
                  color={colorMode=== 'dark' ? 'white' : 'black'}
                  _hover={{ color: "#B72618" }}
                  fontWeight="semibold" fontSize="22px"
                  variant="link"
                    onClick={() => {
                      setLoginOpen(true);
                    }}
                    data-cy="loginBtn"
                  >
                    Log In
                  </Button>
                </Box>
              )}
              </Box>

              <Box
                alignItems="center"
                display="flex"
                ml="2"
                cursor="pointer"
                position="relative"
                onClick={
                  cart !== null && cart !== undefined
                    ? () => {
                        if (
                          cartInvalidFalg !== null &&
                          cartInvalidFalg !== undefined &&
                          cartInvalidFalg
                        ) {
                          navigate(`/availabilitysearch/${cart.id}`);
                        } else {
                          navigate(`/cart/${cart.id}`);
                        }
                      }
                    : undefined
                }
              >
               
                <HiShoppingCart fontSize="24px" />
                <Circle
                  size="20px"
                  bg="#FFBF84;"
                  color="black"
                  position="absolute"
                  top="-5px"
                  right="-10px"
                  fontSize="xs"
                >
                  {cartQty}
                </Circle>
              </Box>
            </Flex>
          </Flex>
          <ChangePasswordView
            onChangePasswordClose={onChangePasswordClose}
            isOpen={changePasswordOpen}
          />
          <Welcome
            isOpen={welcomeOpen}
            setAgeLimitOpen={setAgeLimitOpen}
            setWelcomeOpen={setWelcomeOpen}
          />

          <AgeLimit
            isOpen={ageLimitOpen}
            setAgeLimitOpen={setAgeLimitOpen}
            setLoginOpen={setLoginOpen}
          />
          {updateDeliveryAddressModal && location.pathname === '/' && (
            <AddDeliveryContact
              isOpen={updateDeliveryAddressModal}
              updateDeliveryAddressModalClose={updateDeliveryAddressModalClose}
              updateDeliveryToAddress={updateDeliveryToAddress}
              updateDeliveryToAddressId={updateDeliveryToAddressId}
            />
          )}
          <LoginView
            onLoginViewClose={onLoginViewClose}
            isOpen={loginOpen}
            viewState={viewState}
            setViewState={setViewState}
            userObj={userObj}
            socialLoginViewClose={socialLoginViewClose}
          />
          {idleModalVisible && <Modal
            isOpen={idleModalVisible}
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
            size="sm"
          >
            <ModalOverlay />
            <ModalContent alignItems="center" rounded='40px'>
              <ModalBody p="0" m="0" width="600px" className="blockBg" style={{borderRadius: '40px'}}>
                <Flex direction="column" roundedTopLeft="10" roundedBottomRight="10">
                 
                  <VStack fontFamily="Lexend" alignItems="center"  textAlign="center"  spacing="3" px="10" h="220px" mt="8">
                    <Text as="h1" fontWeight="bold" fontSize="2xl">
                    Still With Us?
                    </Text>
                     <Text >Privacy is essential, you've been inactive for a while. We will log you out in few minutes unless you confirm you're still with us!</Text>
                    {/* <Text fontSize="2xl">Will be there soon</Text> */}
                    <Flex pb="8" direction="row" pt="5" justifyContent="in-between">
                      <HStack>
                        <Button
                          type="cancel"
                          _hover={{ background: 'brand.red' }}
                          onClick={() => {
                            signout();
                            setLoginOpen(true)
                            setIdleModalVisible(false)
                          }}
                          // data-cy="welcomeCloseBtn"
                        >
                          Logout
                        </Button>
                        <Button
                          type="submit"
                          _hover={{ background: 'brand.red' }}
                          onClick={() => {
                            setIdleModalVisible(false);
                            window.location.reload();
                          }}
                          // data-cy="welcomeCloseBtn"
                        >
                          Continue Browsing
                        </Button>
                      </HStack>
                    </Flex>
     
                  </VStack>
                 
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>}
        </Box>
        <TopNavigation deliveryToAddress={user?.deliveryToAddress} />
      </Box>
      {cartInvalidFalg && user &&
      location.pathname !== '/about-us' &&
      location.pathname !== '/careers' ? (
        <Box
          bg="brand.grey"
          sx={{ p: '1', textAlign: 'center', color: '#fff' }}
        >
          Your cart is invalid.Please update the Cart.
          {location.pathname.includes('/availabilitysearch') ? (
            <></>
          ) : (
            <Button
            zIndex={100}
            color="brand.balck"
              sx={{ ml: 2 }}
              onClick={() => {
                console.log("cartInvalidFalg935::",cartInvalidFalg);
                if(cart?.id)
                navigate(`/availabilitysearch/${cart.id}`);
              }}
              // variant="link"
            >
              Update Cart
            </Button>
          )}
        </Box>
      ) : (
        <></>
      )}
      <Modal
      isOpen={isOpen}
      onClose={()=>{setIsOpen(false);
      }}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="lg"
      
    >
      <ModalOverlay />
     
      <ModalContent>
         <ModalHeader color="white" fontSize="lg" bg="brand.red">confirmation</ModalHeader> 
        <ModalCloseButton />
     
      <ModalBody>
    
    Are you sure you want to delete your account?
  </ModalBody>
  <ModalFooter>
     
     <Button
      color="brand.red"
      borderColor="brand.red" 
      variant="cancel-button"
      onClick={()=>{
        navigate('/')
        setIsOpen(false)
      }}>Cancel</Button> 
    
    <Button ml={5} onClick={deleteUser}>Delete</Button>

    </ModalFooter>
    </ModalContent>
  </Modal>
  </>
);

}

 const stateMapper = (state) => ({
  profileImage: state.auth.profileImage,
  user: state.auth?.user,
  sessionExpiry: state.auth?.sessionExpiry,
  cart: state.cart.cart,
  cartInvalidFalg: state.cart.cartInvalidFlag,
  deliveryAddress: state.deliveryAddress?.deliveryAddress,
  deliveryAddressStatus: state.deliveryAddress?.deliveryAddressStatus,
  deliveryToAddressStatus: state.deliveryAddress?.deliveryToAddressStatus,
});

const dispatchMapper = (dispatch) => ({
  signOut: dispatch.auth.signOut,
  setDeliveryToAddressStatus:
    dispatch.deliveryAddress.setDeliveryToAddressStatus,
  setCartInvalidFlag: dispatch.cart.setCartInvalidFlag,
  updateDeliveryAddressStatus:
    dispatch.deliveryAddress.updateDeliveryAddressStatus,
  fetchAndSetUser: dispatch.auth.fetchAndSetUser,
});

export default connect(stateMapper, dispatchMapper)(TopBar);
