import { Box, Divider, Flex, Link, Text, Button } from '@chakra-ui/react';
import React from 'react';
import { HStack,Image ,useColorModeValue,Icon} from  '@chakra-ui/react';
import awsExports from '../../../aws-exports';
import { useColorMode } from "@chakra-ui/react"; 
import { FiExternalLink } from 'react-icons/fi';

export default function Footer() {
  const { colorMode } = useColorMode();

  const agentlink = awsExports.aws_redirect;
  const merchantlink = awsExports.aws_merchantredirect;
  const promotionallink = awsExports.aws_promtionalredirect;
  const openPlayStore = () => {
    window.location.href = "spiritscustomerapp://127.0.0.1:19000"; // Try to open the app
    setTimeout(() => {
      window.location.href = "https://play.google.com/store/apps/details?id=com.spirits.customer&pcampaignid=web_share";
    }, 1000); // Adjust the timeout duration as needed
   // Adjust the timeout duration as needed for the Play Store
  
  };

  const openDeeplink = () => {
    const baseURL = "spiritscustomerapp://127.0.0.1:19000/category";
    const queryParams = encodeURI("field=prodCategoryBeerLiquor%26prodCategory1=Liquor%26prodSubCategory1=Whiskey/Bucketfilter?stores=Super%20Buy%20Rite%20Jersey%20City%26availableQty=In%20Stock");
    const fullURL = `${baseURL}?${queryParams}`;
   
    window.location.href = fullURL;
    setTimeout(() => {
      window.location.href = "https://play.google.com/store/apps/details?id=com.spirits.customer&pcampaignid=web_share";
    }, 1000);
  };
  
  
  
  const openAppStore = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const appScheme = "spiritscustomerapp://127.0.0.1:19000";
    const appStoreURL = "https://apps.apple.com/us/app/1800spirits-alcohol-ordering/id6463494662";
  
    if (isIOS) {
      // Create an iframe to attempt to open the app
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = appScheme;
      document.body.appendChild(iframe);
  
      // Set a timeout to redirect to the App Store if the app is not opened
      setTimeout(() => {
        document.body.removeChild(iframe);
        window.location.href = appStoreURL;
      }, 1500); // Adjust the delay as needed
    } else {
      // For non-iOS devices, simply redirect to the App Store
      window.location.href = appStoreURL;
    }
  };
  
  
  
  const currentYear = new Date().getFullYear();
  const handleBreadcrumbClick = () => {
    localStorage.removeItem('selectedStoreName');
    localStorage.removeItem('selectedStoreId');
  };

  return (
    <Box
    borderTop='1px solid #B2B2B2' >
      <Box   p="3"
      bg={colorMode === "dark" ? "#121212" : "#FFF"}
      color={colorMode === "dark" ? "#FFF" : "#121212"}>
        <Box m="auto">
          <Flex direction="row" justifyContent="space-between"
          >
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              // height="18px"
              // h={{ base: 'max-content', lg: '20px' }}
              // mb="2"
              // mt="2"
              justifyContent="top"
              // display= "inline-flex"
              align-items= "flex-start"

              gap= "20px"
              fontFamily= "Lexend"
              fontSize= "14px"
              fontStyle= "normal"
              fontWeight= "500"
              lineHeight= "normal"
            >
              {/* <Text as="p" size="sm" fontWeight="bold" pr="3">
                Quick Links :
              </Text> */}

              <Link href="/storelocator" width="auto" _hover={{ color: "#B72618" }}  fontWeight='bold ' onClick={handleBreadcrumbClick}>
                Stores Near me
              </Link>
              {/* <Divider orientation="vertical" pr="3" /> */}
              <Link
                href={merchantlink}
                target="_blank"
                width="auto"
                // textShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
                fontWeight='bold'
                _hover={{ color: "#B72618" }}
              >
                Merchant Portal
              </Link>
              {/* <Divider orientation="vertical" pr="3" /> */}
              <Link
                href={agentlink}
                target="_blank"
                width="auto"
                // textShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
                fontWeight='bold'
                _hover={{ color: "#B72618" }}
              >
                Agent Portal
              </Link>
              {/* <Divider orientation="vertical" pr="3" /> */}
              <Link
                href={promotionallink}
                target="_blank"
                width="auto"
                // textShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
                fontWeight='bold'
                _hover={{ color: "#B72618" }}
                
              >
                Partner with us{' '}
              </Link>
              <Link href="/about-us"  target="_blank" width="auto" _hover={{ color: "#B72618" }} fontWeight='bold'>
                About Us{' '}
              </Link>
              <Link target="_blank" href="/careers"  width="auto" _hover={{ color: "#B72618" }}  fontWeight='bold'>
                Careers{' '}
              </Link>{' '}
            </Flex>
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              // direction="row"
              justifyContent="top"
              fontWeight="bold"
              height="15px"
              
              gap="17px"
            >
               <Text as="p" size="sm" mb="2" mt="2"  textShadow={'0px 1px 2px rgba(0, 0, 0, 0.25)'} fontWeight="bold" width="83px">
                      Get the App
              </Text>
              <Box
              width='95px'
              marginRight={1}
              onClick={openAppStore}
              _hover={{ cursor: "pointer" }}
            
              border="0px"
            >
              <Image src={require('../../../assets/icons/Appstore.svg')} />
            </Box>
            <Box
              width='108px'
              onClick={openPlayStore}
              _hover={{ cursor: "pointer" }}
              
              border="0px"
            
            >
              <Image src={require('../../../assets/icons/google-play-badge.svg')} />
            </Box>
            <Box
bg="transparent"
borderRadius="full"
boxSize="6"
display="inline-flex"
justifyContent="center"
alignItems="center"
transition="background-color 0.2s"
_hover={{ bg: "gray.200" }}
>
  <Link onClick={openDeeplink}>
    <Icon
      as={FiExternalLink} // Use the desired icon component
      boxSize="6"
      color={useColorModeValue('White', 'Black')}
    />
  </Link>
</Box>

            </Flex>
          </Flex>
        
          <Flex direction="row" mt="3" justifyContent="space-between">
            <Flex>
              {/* <Text as="p" size="sm" mb="3" pr="3" fontWeight="bold" mt = "4">
                      Get the App :
              </Text>
              <Button
                  width='145px'
                  marginRight={1}
                  onClick={openAppStore}
                >
                <Image src={require('../../../assets/icons/Appstore.svg')} />
                </Button>
                <Button
                  width='150px'
                  onClick={openPlayStore}
                >
                  <Image src={require('../../../assets/icons/google-play-badge.svg')} />
                </Button> */}
                </Flex>
          
            {/* <Flex direction="row" mt="3" pr="3">
              <Text as="p" size="sm" mb="3" pr="3" fontWeight="bold">
                REACH US :
              </Text>
              <Text as="p">
                2162 Route 206, 2nd Floor, Montgomery NJ 0852, USA
              </Text>
            </Flex> */}
          </Flex>
        </Box>
      </Box>
      <Flex justify="center" direction="row"  pb="2" pr="3" 
      fontFamily= "Lexend"
      fontSize= "14px"
      fontStyle= "normal"
      fontWeight= "500"
      lineHeight= "normal"
     
      >  
            

        <Text  fontWeight="bold"  >
         Copyright &copy; {currentYear}, 1800spirits
        </Text>       
           <Link target="_blank" href="/terms-of-use" ml="8" pr="3" fontWeight="bold" _hover={{ color: "#B72618" }} >
              Terms Of Use{' '}
              </Link>{' '}
              <Link target="_blank" href="/privacy-policy" ml="5" pr="3" fontWeight="bold" _hover={{ color: "#B72618" }} >
              Privacy Policy{' '}
              </Link>{' '}       
      </Flex>    
    </Box>
  );
}
